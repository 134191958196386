import React, { Component } from "react";
import AppContext from "../../AppContext";
import { Alert } from "reactstrap";
import { Icon } from '@morgan-stanley/campfire-client-react';

class MS_Header extends Component {
  constructor(props) {
    super(props);
  }

  static contextType = AppContext;

  renderSwitch(customClient) {
    let leftHeader1 = <img className="ms-header-logo" alt="" />;
    switch (customClient) {
      case 'guardiannq':
        return leftHeader1;
      case 'mercer':
        return leftHeader1;
      default:
        return leftHeader1 = (
          <a
            id="logo-lg"
            href="https://www.morganstanley.com/atwork/deferred-compensation-plans?icid=mnsltwk-tl-sunrise-6095"
          >
            <img className="ms-header-logo" alt="" />
          </a>
        );
    }
  }

  render() {
    var PageUrl = window.location.href;
    let imageEnv;
    let className;
    var PageName;
    if (top.location.hostname.indexOf(".") != -1) {
      PageName = top.location.hostname
        .substring(0, top.location.hostname.indexOf("."))
        .toLowerCase();
    } else {
      PageName = top.location.hostname.toLowerCase();
    }

    if (PageName == "localhost") {
      imageEnv = <img className="ms-header-sit-logo" alt="" data-testid="sit-logo" />;
    } else if (PageName == "login-dev-nqdc") {
      imageEnv = <img className="ms-header-sit-logo" alt="" data-testid="sit-logo" />;
    } else if (PageName == "uatlogin") {
      imageEnv = <img className="ms-header-uat-logo" alt="" data-testid="uat-logo" />;
    } else if (PageName == "login-qa-nqdc") {
      imageEnv = <img className="ms-header-qa-logo" alt="" data-testid="qa-logo" />;
    }

    return (
      <header className="migration-header">
        <div className="joy0 joy6 app-header navbar justify-content-start">
          {imageEnv}
          {this.renderSwitch(this.context.custom_client)}
          {this.context.custom_client.indexOf("login") == -1 && (
            <img
              className="ms-header-logo2"
              src={
                "img/private_custom_login/" +
                this.context.custom_client +
                "/images/logo.png"
              }
              alt=""
              data-testid="logo-2-img"
            />
          )}
        </div>
        {this.props.bannerText && (
          <Alert color="primary">
            <Icon name="info" className="mr-3" size="xs" />
            <span
              className="ms-text"
              dangerouslySetInnerHTML={{ __html: this.props.bannerText }}
            ></span>
          </Alert>
        )}
      </header>
    );
  }
}


export default MS_Header;
