import React, { Component } from "react";
import packageJson from "../../../package.json";
import { Row, Col } from "reactstrap";
import AppContext from "../../AppContext";
import ReactDOM from "react-dom";

class MS_Footer extends Component {
  static contextType = AppContext;

  openComponentInNewTab = () => {
    const currentUrl = window.location.href;
    const baseUrl = new URL(currentUrl).origin;

    const newTab = window.open("", "_blank");
    newTab.document.title = "Terms and Conditions";
    newTab.document.body.innerHTML = '<div id="root"></div>';

    const urlParams = new URLSearchParams();
    urlParams.set("page", "TermsAndConditions");

    import("../../containers/Full/StaticTermsAndConditions").then(
      ({ default: StaticTermsAndConditions }) => {
        const rootElement = newTab.document.getElementById("root");
        const root = ReactDOM.createRoot(rootElement);

        root.render(
          <React.StrictMode>
            <StaticTermsAndConditions baseUrl={baseUrl} />
          </React.StrictMode>
        );
      }
    );
  };

  handleprivacy_choices = (event) => {
    event.preventDefault();
    // Ejecuta el script
    window.privacy_choices();
  };

  render() {
    let dtoday = new Date();
    let currYear = dtoday.getFullYear();
    const isSiteDown = process.env.NODE_ENV === undefined;
    return (
      // <footer className={"footer-fixed app-footer"}>
      <footer className="app-footer">
        <Row className="ms-footer-body">
          <Col className="bootstrapTable-center text-center">
            <font color="black" size="2">
              Nonqualified deferred compensation plan recordkeeping services are
              part of Morgan Stanley at Work solutions and are offered by
              E*TRADE Financial Corporate Services, Inc.
              <br />
              <br />
              E*TRADE Financial Corporate Services, Inc. is a wholly-owned
              subsidiary of Morgan Stanley.
              <br />
              <br />
              Tax laws are complex and subject to change. Morgan Stanley and
              E*TRADE Financial Corporate Services, Inc., their affiliates and
              their employees and agents do not provide tax or legal advice.
              Employers (and other service recipients) should consult their own
              tax and legal advisors before establishing a nonqualified deferred
              compensation plan, and regarding any potential legal, tax, and
              other consequences of any investments or other transactions made
              with respect to a nonqualified deferred compensation plan.
              Eligible employees (and other eligible service providers) should
              consult their own tax and legal advisors before deciding to
              participate in, or making any elections with respect to, a
              nonqualified deferred compensation plan.
              <br />
              <span
                className=""
                style={{ marginLeft: "6rem", display: "inline-block" }}
              >
                <a
                  className="aMScolor"
                  target="_blank"
                  href="https://www.morganstanley.com/disclaimers/us-privacy-policy-and-notice"
                >
                  Privacy
                </a>
                {"\u00A0\u00A0\u00A0|\u00A0\u00A0"}
                <a
                  className="message-link message-link-font-ms"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={this.openComponentInNewTab}
                >
                  Terms and Conditions
                </a>
              </span>
              {/* {"\u00A0\u00A0\u00A0|\u00A0\u00A0\u00A0"}
              <a
                className="aMScolor"
                target="_blank"
                href="#"
                onClick={this.handleprivacy_choices}
              >
                Your Privacy Choices
              </a>
              {"\u00A0\u00A0"}
              <img
                className="privacyoptions"
                src={"img/privacyoptions730x350.png"}
                alt=""
                onClick={this.handleprivacy_choices}
              /> */}
              <br />
              <span
                className=""
                style={{ marginLeft: "5rem", display: "inline-block" }}
              >
                {packageJson.copyright}
                {currYear}
                {" - "}
                E*TRADE Financial Corporate Services, Inc.
              </span>
            </font>
          </Col>
        </Row>
        <Row className="ms-footer-version">
          <Col className="bootstrapTable-center text-center">
            <font color="black" size="2">
              {isSiteDown ? null : packageJson.version}
            </font>
          </Col>
        </Row>
        {/* <Row
          className=""
          style={{ width: "150%", height: "30px", backgroundColor: "white" }}
        >
          <Col className="bootstrapTable-center text-center">
            <font color="black" size="2">
              {isSiteDown? null : packageJson.version}
            </font>
          </Col>
        </Row> */}
      </footer>
    );
  }
}

export default MS_Footer;
