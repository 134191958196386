import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

class ModalControl extends React.Component {
  constructor(props) {
    super(props);

    this.togglemodalOK = this.togglemodalOK.bind(this);
    this.togglemodalYesNo = this.togglemodalYesNo.bind(this);

    this.togglemodalOFF = this.togglemodalOFF.bind(this);
    this.togglemodalOKClose = this.togglemodalOKClose.bind(this);

    this.state = {
      modal: false,
      modalYesNo: false,
      okClose: false,
    };

    this.HeaderLabel = "";
    this.BodyLabel = "";
    this.Type = 1;
    this.severity = null;
    this.okFunction = () => {};
  }

  togglemodalOK(strHeader, strBody, severity, okFunction) {
    let sev = severity == null || severity === undefined ? null : severity;
    this.headerLabel = strHeader;
    this.bodyLabel = strBody;
    this.severity = sev;
    if (okFunction !== undefined) this.okFunction = okFunction;
    this.setState({
      modal: true,
      modalYesNo: false,
      okClose: false,
    });
  }

  togglemodalOKClose(strHeader, strBody, okFunction) {
    this.headerLabel = strHeader;
    this.bodyLabel = strBody;
    this.severity = null;
    this.okFunction = okFunction;
    this.setState({
      modal: true,
      modalYesNo: false,
      okClose: true,
    });
  }

  togglemodalYesNo(strHeader, strBody, okFunction) {
    this.headerLabel = strHeader;
    this.bodyLabel = strBody;
    this.severity = null;
    this.okFunction = okFunction;
    this.setState({
      modal: true,
      modalYesNo: true,
      okClose: false,
    });
  }

  togglemodalOFF() {
    this.setState({
      modal: false,
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps !== this.props || nextState !== this.state) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <Modal isOpen={this.state.modal}>
        <ModalHeader>{this.headerLabel}</ModalHeader>
        <ModalBody className="modal-body">
          <div
            className={
              this.severity == null
                ? ""
                : this.severity >= 2
                ? "errorMsgRed"
                : "errorMsgYellow"
            }
            dangerouslySetInnerHTML={{ __html: this.bodyLabel }}
          />
        </ModalBody>
        <ModalFooter className="modal-footer">
          {!this.state.modalYesNo ? (
            !this.state.okClose ? (
              <div>
                <Button
                  color="primary"
                  className="btn-next"
                  onClick={() => {
                    this.togglemodalOFF();
                    this.okFunction();
                  }}
                >
                  OK
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  color="primary"
                  className="btn-next"
                  onClick={() => {
                    this.togglemodalOFF();
                    this.okFunction();
                  }}
                >
                  OK
                </Button>
              </div>
            )
          ) : (
            <div>
              <Button
                color="green"
                className="btn-save"
                onClick={() => {
                  this.togglemodalOFF();
                  this.okFunction();
                }}
              >
                Accept
              </Button>
              <Button
                color="red"
                className="btn-cancel"
                onClick={() => {
                  this.togglemodalOFF();
                }}
              >
                Cancel
              </Button>
            </div>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}
export default ModalControl;
