import React, { Component } from "react";
import { Row, Col, Button, Container } from "reactstrap";

import AppContext from "../../AppContext";
import AFSMessage from "../../components/Controls/AFSMessage";
import "../../scss/quill.snow.css";
// const DEFAULT_QUERY_contactUs = "Login/GetContact";

class ContactUs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      forcePaint: false,
    };

    // this.data = "";

    // this.getContact = this.getContact.bind(this);
    // this.displayMessage = this.displayMessage.bind(this);
  }

  static contextType = AppContext;

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress);
  }

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.props.changeMenuIndex("Login");
    }

    // if (event.key === 'Tab') {
    //   event.preventDefault();
    //   const { target } = event;
    //   const tabIndex = parseInt(target.getAttribute('tabIndex'));

    //   const nextElement = document.querySelector(`[tabIndex="${tabIndex + 1}"]`);

    //   if (nextElement) {
    //     nextElement.focus();
    //   }
    // }
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps !== this.props || nextState !== this.state) {
      return true;
    }
    return false;
  }

  // displayMessage(typeMessage, message) {
  //   // this.form.current.togglemodalOK(typeMessage, message);
  // }

  // getContact() {
  //   axios
  //     .all([
  //       axios.get(
  //         this.context.API_URL_HOST +
  //         DEFAULT_QUERY_contactUs +
  //         "?" +
  //         this.context.encrypt("customLogin=" + this.context.custom_client)
  //       ),
  //     ])
  //     .then(
  //       axios.spread((resp) => {
  //         if (resp.data.errorMessage != 0) {
  //           this.displayMessage("Failed Validation", resp.data.msgError);
  //         } else {
  //           this.data = resp.data.data;
  //           this.setState({ forcePaint: !this.state.forcePaint });
  //         }
  //       }
  //       ))
  //     .catch((error) => {
  //       console.log(error);
  //       this.props.displayError(0, "", error);
  //     });
  // }

  render() {
    return (
      <div className="app-body">
        <main className="main">
          <Container>
            <Row className="row-center">
              <Col className="col-lg-3 col-md-2 col-2" />
              <Col className="col-lg-6 col-md-8 col-8">
                <Row className="row-padding logon">
                  <Col className="ms-header text-center" data-testid="contactus">Contact us</Col>
                </Row>
                {/* <Row className="mt20">
                  <Col className="ms-text text-center"
                  data-testid="assistance">
                    For assistance navigating the website or to ask questions
                    about your account, please contact:
                  </Col>
                </Row> */}
                <Row className="mt20">
                  <Col className="ms-text text-center">
                    <AFSMessage
                      hidden={false}
                      collapsable={false}
                      msg={this.props.data}
                      // className={"text-center"}
                      isTable={false}
                    />
                  </Col>
                </Row>
              </Col>
              <Col className="col-lg-3 col-md-2 col-2" />
            </Row>
            <Row className="mb20">
              <Col className="text-center">
                <Button
                  className="btn btn-secondary ms-logon-btn btn-save-ms"
                  onClick={() => this.props.changeMenuIndex("Login")}
                >
                  Back
                </Button>
              </Col>
            </Row>
          </Container>
        </main>
      </div>
    );
  }
}

export default ContactUs;
