import React from "react";
import { Input, InputGroup } from "reactstrap";

import { Icon } from "@morgan-stanley/campfire-client-react";

class InputControl extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    // this.escFunction = this.escFunction.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.state = {
      value: props.value,
      lastValue: props.value,
      errorMessage: "",
      hiddenMessage: props.displayError != undefined,
    };
  }

  numberOfDecimals(expression) {
    let j;
    let i = 0;
    let blnHasDecimals = false;
    let c; // A single character.
    let strAux = expression.toString();

    for (j = 0; j < strAux.length; j++) {
      // Look at all characters.
      c = strAux.charAt(j); // Look at each character.
      if (c >= "0" && c <= "9") {
        if (blnHasDecimals) {
          i++;
        }
      }
      if (c == ".") {
        // Decimal point?
        if (!blnHasDecimals) {
          // If we haven't seen one already,
          blnHasDecimals = true; // now we have.
        }
      }
    }
    return i;
  }

  validateNumber(value, err) {
    if (
      this.props.required !== undefined &&
      this.props.required == true &&
      value == null
    ) {
      err.msg = "This field can not be empty, please enter a valid number.";
      return false;
    } else if (
      (this.props.required === undefined || this.props.required == false) &&
      value == null
    ) {
      return true;
    }
    if (isNaN(Number.parseFloat(value))) {
      err.msg = "A number is required, please enter a valid number.";
      return false;
    }
    if (
      this.props.minVal != undefined &&
      this.props.minVal > Number.parseFloat(value)
    ) {
      err.msg = "This field can not be less than " + this.props.minVal + ".";
      return false;
    }
    if (
      this.props.maxVal != undefined &&
      this.props.maxVal < Number.parseFloat(value)
    ) {
      err.msg = "This field can not be more than " + this.props.maxVal + ".";
      return false;
    }
    if (
      this.props.valueException != undefined &&
      this.props.valueException == Number.parseFloat(value)
    ) {
      err.msg = "This field can not be " + this.props.valueException + ".";
      return false;
    }
    if (this.props.increments != undefined) {
      let increment = Number.parseFloat(this.props.increments);
      if (increment > 0) {
        // If it has to be bigger than a minimum value,
        let fldValue = Number.parseFloat(value);
        let rem =
          fldValue - Math.floor(fldValue / increment + 0.000001) * increment;
        if (rem > 0.0000001) {
          err.msg = "This field must be in increments of " + increment + ".";
          return false;
        }
      }
    }
    if (this.props.decimals != undefined) {
      let decimals = Number.parseFloat(this.props.decimals);
      if (decimals >= 0) {
        // If it has to have N decimals
        let number_of_decimals =
          0 + this.numberOfDecimals(Number.parseFloat(value));
        if (number_of_decimals > decimals) {
          if (decimals == 0) {
            err.msg = "This field should not have decimals.";
          } else {
            err.msg =
              "This field should have not more than " + decimals + " decimals.";
          }
          return false;
        }
      }
    }
    return true;
  }

  phoneParser(newValue, parameters) {
    let newPhone = newValue.replace("(", "").replace(")", "").replace("-", "");
    parameters.countryCode = "";
    let space = -1;
    if (newPhone.substring(0, 1) == "+") {
      space = newPhone.indexOf(" ");
      if (space == -1) {
        if (newPhone.length > 4) {
          parameters.countryCode = newPhone.substring(0, 4) + " ";
          newPhone = newPhone.substring(4);
        } else {
          parameters.countryCode = newPhone;
          newPhone = "";
        }
      } else {
        parameters.countryCode = newPhone.substring(0, space + 1);
        newPhone = newPhone.substring(space + 1);
      }
    }
    space = 10;
    while (space != -1) {
      newPhone = newPhone.replace(" ", "");
      space = newPhone.indexOf(" ");
    }

    parameters.extension = "";
    if (newPhone.length > 10) {
      parameters.extension = newPhone.substring(10);
      if (parameters.extension.substring(0, 1) == " ") {
        parameters.extension = extension.substring(1);
      }
      newPhone = newPhone.substring(0, 10);
    }
    return newPhone;
  }

  handleChange(event) {
    let msg = "";
    if (event.target.value.length > 1 && this.state.value == "") {
      this.handleBlur(event);
    }
    switch (this.props.type) {
      case "phone":
        let parameters = { countryCode: "", extension: "" };
        let oldPhone = this.phoneParser(this.state.value, parameters);
        let newPhone = this.phoneParser(event.target.value, parameters);
        let re = /^[0-9]{0,10}$/g;
        if (newPhone == "" || re.test(newPhone)) {
          if (newPhone.length > 3) {
            newPhone =
              "(" + newPhone.substring(0, 3) + ") " + newPhone.substring(3);
          }
          if (newPhone.length > 9) {
            newPhone = newPhone.substring(0, 9) + "-" + newPhone.substring(9);
          }
          newPhone =
            parameters.countryCode +
            newPhone +
            (parameters.extension == "" ? "" : " " + parameters.extension);
          this.setState({ value: newPhone });
        } else if (oldPhone != "" && !re.test(oldPhone)) {
          this.setState({ value: event.target.value });
        }
        break;
      case "email":
      case "text":
        this.setState({ value: event.target.value });
        break;
      case "number":
        let re1 = /^[-]{0,1}([1-9]{0,1}[0-9]{0,100})[.]{0,1}[0-9]{0,100}$/g;
        if (event.target.value == "") {
          this.setState({ value: "" });
        } else if (re1.test(event.target.value)) {
          this.setState({ value: event.target.value });
        }
        break;
      case "file":
        let err = { msg: "" };
        if (event.target.files[0].name != this.state.lastValue) {
          if (this.props.onChange != undefined) {
            let ret = this.props.onChange(
              this.props.name,
              event.target.files[0].name,
              this.state.lastValue,
              err,
              event.target.files[0]
            );
            if (ret === -1) {
              this.setState({ value: this.state.lastValue });
            } else if (ret === true) {
              this.setState({ lastValue: event.target.files[0].name });
            } else {
              event.target.focus();
            }
          } else {
            this.setState({ lastValue: event.target.files[0].name });
          }
        }
        this.setState({ value: event.target.files[0].name });
        break;
    }
  }

  handleBlur(event) {
    let err = { msg: "" };
    let newValue = event.target.value;
    switch (this.props.type) {
      case "email":
        if (event.target.value != this.state.lastValue) {
          let re =
            /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/g;

          if (event.target.value == "" || re.test(event.target.value)) {
            if (this.props.onChange != undefined) {
              if (
                this.props.onChange(
                  this.props.name,
                  event.target.value,
                  this.state.lastValue,
                  err,
                  this.props.subField
                )
              ) {
                this.setState({ lastValue: event.target.value });
              } else {
                event.target.focus();
                //this.setState({value: this.state.lastValue});
              }
            } else {
              this.setState({ lastValue: event.target.value });
            }
          } else {
            err = { msg: "Please Enter a Valid Email" };
            this.props.displayError(this.props.name, newValue, err.msg);
            event.target.focus();
          }
        }
        break;
      case "phone":
      case "text":
        if (event.target.value != this.state.lastValue) {
          if (this.props.onChange != undefined) {
            let ret = this.props.onChange(
              this.props.name,
              event.target.value,
              this.state.lastValue,
              err
            );
            if (ret === -1) {
              this.setState({ value: this.state.lastValue });
            } else if (ret === true) {
              this.setState({ lastValue: event.target.value });
            } else {
              event.target.focus();
              //this.setState({value: this.state.lastValue});
            }
          } else {
            this.setState({ lastValue: event.target.value });
          }
        }
        break;
      case "number":
        if (newValue == "") {
          newValue = null;
        } else {
          newValue = Number(newValue);
        }
        if (
          newValue !== this.state.lastValue &&
          event.target.value !== this.state.lastValue
        ) {
          if (this.validateNumber(newValue, err)) {
            if (this.props.onChange != undefined) {
              let ret = this.props.onChange(
                this.props.name,
                newValue,
                this.state.lastValue,
                err
              );
              if (ret === -1) {
                this.setState({ value: this.state.lastValue });
              } else if (ret === true) {
                this.setState({ lastValue: newValue });
              } else {
                event.target.focus();
                //this.setState({value: this.state.lastValue});
              }
            } else {
              this.setState({ lastValue: newValue });
            }
          } else {
            if (this.props.displayError != undefined) {
              this.props.displayError(this.props.name, newValue, err.msg);
            }
            event.target.focus();
            //this.setState({value: this.state.lastValue});
          }
        }
        break;
    }
    if (!this.state.hiddenMessage) {
      this.setState({ errorMessage: err.msg });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps !== this.props || nextState !== this.state) {
      if (nextProps.value !== this.props.value) {
        this.setState({ value: nextProps.value, lastValue: nextProps.value });
        return false;
      }
      if (nextProps.displayError != this.props.displayError) {
        this.setState({ hiddenMessage: props.displayError != undefined });
        return false;
      }
      return true;
    }
    return false;
  }

  handleKeyDown(event, init_value) {
    if (event.keyCode === 27) {
      this.setState({
        value: init_value,
      });
    }
    if (this.props.onKeyUp != undefined && event.keyCode !== 27) {
      this.props.onKeyUp(this.state.value);
    } else if (this.props.onKeyUp != undefined && event.keyCode === 27) {
      this.props.onKeyUp(init_value);
    }
  }

  render() {
    let maxLength = this.props.maxLength;
    if (maxLength === undefined) maxLength = 50;
    let placeholder = this.state.placeholder;
    if (placeholder === undefined) placeholder = "";
    return (
      <InputGroup className={this.props.wrapClassName}>
        {this.props.icon != undefined && (
          <div
            className={
              "input-group-prepend input-group-text " + this.props.iconClassName
            }
          >
            <Icon name={this.props.icon} size="xxs" />
          </div>
        )}
        {this.props.prefix != undefined && (
          <React.Fragment>{this.props.prefix}</React.Fragment>
        )}
        <Input
          type={this.props.type === "file" ? this.props.type : "text"}
          id={this.props.id}
          name={this.props.name}
          autoFocus={this.props.autoFocus}
          className={this.props.className}
          disabled={
            this.props.disabled != undefined ? this.props.disabled : false
          }
          placeholder={placeholder}
          value={
            this.props.type === "file" && this.state.value == null
              ? ""
              : this.props.type === "file"
              ? undefined
              : this.state.value
          }
          onChange={this.handleChange}
          onKeyUp={(e) => this.handleKeyDown(e, this.state.lastValue)}
          onBlur={this.handleBlur}
          maxLength={maxLength}
          required={this.props.required}
        />
        {this.props.suffix != undefined && (
          <React.Fragment>{this.props.suffix}</React.Fragment>
        )}
        {this.props.iconpost != undefined && (
          <div
            className={
              "input-group-prepend input-group-text " + this.props.iconClassName
            }
          >
            <Icon name={this.props.iconpost} size="xxs" />
          </div>
        )}
        {this.props.requiredMark != undefined && this.props.requiredMark && (
          <font color="red">{"\u00A0"}*</font>
        )}
        <div
          hidden={this.state.hiddenMessage}
          className="col-form-label text-red"
        >
          {this.state.errorMessage}
        </div>
      </InputGroup>
    );
  }
}

export default InputControl;
