import React from "react";
import classnames from "classnames";
import { Card, CardBody } from "reactstrap";

import { Icon } from "@morgan-stanley/campfire-client-react";

class AFSMessage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps !== this.props || nextState !== this.state) {
      return true;
    }
    return false;
  }

  getMessage() {
    return (
      <React.Fragment>
        {this.props.collapsable == false ? (
          ""
        ) : (
          <a
            className="message-link"
            data-testid="linkElement"
            href="#"
            onClick={() => {
              this.setState({ visible: !this.state.visible });
            }}
          >
            {" "}
            {this.state.visible ? "Hide Message " : "Show Message "}
            <Icon
             data-testid="icon"
              name={
                this.state.visible ? "keyboard_arrow_up" : "keyboard_arrow_down"
              }
              size="sm"
            />
            &nbsp;&nbsp;
          </a>
        )}

        {this.state.visible ? (
          <Card className={classnames("card-hide", "message-padding")} data-testid="card">
            <CardBody className="message-padding">
              <div
                className={classnames(
                  "NoVerticalScroll",
                  "ql-editor",
                  this.props.className
                )}
                dangerouslySetInnerHTML={{ __html: this.props.msg }}
              />
            </CardBody>
          </Card>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }

  render() {
    let hidden = this.props.hidden !== undefined ? this.props.hidden : false;
    return (
      <React.Fragment>
        {this.props.msg != undefined && this.props.msg != "" && !hidden
          ? this.getMessage()
          : ""}
      </React.Fragment>
    );
  }
}

export default AFSMessage;
