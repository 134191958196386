import React from "react";
import { Icon } from "@morgan-stanley/campfire-client-react";

class HelpControl extends React.Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps !== this.props || nextState !== this.state) {
      return true;
    }
    return false;
  }

  showHelp(helpPage, helpID) {
    let URL =
      this.props.CLASSIC_URL +
      "DTS/reactAdminGateway.asp?target=help&page=" +
      helpPage;
    if (helpID != undefined) URL += "&el_id=" + helpID;
    URL += "&token=" + this.props.encrypt(this.props.API_TOKEN.substring(7));
    let URLOpt =
      "toolbar=0,scrollbars=1,location=0,statusbar=0,menubar=0,resizable=1,width=800,height=600";
    window.open(URL, "_blank", URLOpt);
  }

  render() {
    return (
      <a
        className="button-to-link2 sort-icon"
        role="link"
        onClick={() => {
          this.showHelp(this.props.helpPage, this.props.helpID);
        }}
      >
        <Icon size={this.props.size} color="#3b7ebb" name="et-help-more-info" />
      </a>
    );
  }
}

export default HelpControl;
