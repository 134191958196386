import React from "react";
import { NullRecord } from "../../constants/api_host";
import InputControl from "./InputControl";
import PasswordInputControl from "./PasswordInputControl";
import ModalControl from "./ModalControl";
import CheckBoxControl from "./CheckBoxControl";
import HelpControl from "./HelpControl";
import Moment from "moment";
import FormatNumberControl from "./FormatNumberControl";
import {
  Table,
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import { Icon } from "@morgan-stanley/campfire-client-react";
// 2021-11-01 - RR    import { MapsTransferWithinAStation } from "material-ui/svg-icons";

const ASC = "ascending";
const DSC = "descending";
var parse = require("html-react-parser");

class FormControl extends React.Component {
  constructor(props) {
    super(props);

    // 	Valid attributes:
    // 		name: Form name, will be added to the each field name, so to make it unique it would have to include planID
    //    initialStatus: 0 -> display, 1 -> edit, 3 -> add new
    //        (valid status: 0 display, 1 edit, 2 edit-modified, 3 new, 4 deleted, 5 added-deleted)
    // 		AllowInsert: false -> no, true -> yes (default false)
    // 		AllowDelete: false -> no, true -> yes (default false)
    // 		displayOnly: false -> Edit is allowed, true -> only display is allowed (default false)
    // 		className: class of the table
    //    classNameRow: class of the row for RowField elements
    // 		className1: class of the first row for RowField elements
    // 		className2: class of the second row for RowField elements
    //    tableRow: use in GridControl to display the whole form inside a tr tag.
    //    tableDisplay: encapsulate the whole form in a table tag. (used in GridControl)
    //    hideRequiredMark: Do not show required mark (red *) on the form.
    // 		helpPage: help page that will be triggered on click of any ? image.
    // 		*NoRowsMessage: Message use if there are no rows to display and Insert is not allowed.
    // 			(default: No records were found with the selected criteria)
    // 	  display: is the collection of elements, that will determine the display of the page.
    //    data: Collection of fields that are going to be displayed.
    // 	Valid elements are:
    // 	Fragment:
    // 		Creates a React.Fragment tag.
    // 		Valid attributes:
    // 			className: class for the tag
    // 			EditOnly: false-> no, true -> yes (default false) if Yes the column will only be displayed during edit (displayOnly = false)
    // 			displayOnly: false-> no, true -> yes (default false) if Yes the column will not be displayed during edit (displayOnly = true)
    // 		  hidden: specify if the row needs to be shown or not. (default: 'false')
    // 		  hiddenOnField: If the field included in this attribute is false then the field is displayed. (default: '')
    // 		  hiddenOnValue: If the field specified on hiddenOnField has this value then the field is hidden (default: '')
    // 	Table:
    // 		Creates a table HTML tag, attributes on this element will be attributes for the table tag.
    // 		Valid attributes:
    // 			className: class for the tag
    // 			EditOnly: false-> no, true -> yes (default false) if Yes the column will only be displayed during edit (displayOnly = false)
    // 			displayOnly: false-> no, true -> yes (default false) if Yes the column will not be displayed during edit (displayOnly = true)
    // 		  hidden: specify if the row needs to be shown or not. (default: 'false')
    // 		  hiddenOnField: If the field included in this attribute is false then the field is displayed. (default: '')
    // 		  hiddenOnValue: If the field specified on hiddenOnField has this value then the field is hidden (default: '')
    // 	Row, GridRow, Col, GridCol, RowCol, GridRowCol:
    // 		  GridRow Creates a tr HTML tag
    // 		  GridCol Creates a td HTML tag
    // 		  GridRowCol Creates a tr and td HTML tag
    // 		  Row Creates a Row React element
    // 		  Col Creates a Col React element
    // 		  RowCol Creates a Row and Col React elements
    // 		Valid attributes:
    // 			className: class for the tag
    // 			EditOnly: false-> no, true -> yes (default false) if Yes the column will only be displayed during edit (displayOnly = false)
    // 			displayOnly: false-> no, true -> yes (default false) if Yes the column will not be displayed during edit (displayOnly = true)
    // 		  hidden: specify if the row needs to be shown or not. (default: 'false')
    // 		  hiddenOnField: If the field included in this attribute is false then the field is displayed. (default: '')
    // 		  hiddenOnValue: If the field specified on hiddenOnField has this value then the field is hidden (default: '')
    // 	Label:
    // 		Creates plain text, the text inside this element will be show as a label on the page.
    // 		Valid attributes:
    // 			className: class for the tag
    // 			EditOnly: false-> no, true -> yes (default false) if Yes the column will only be displayed during edit (displayOnly = false)
    // 			displayOnly: false-> no, true -> yes (default false) if Yes the column will not be displayed during edit (displayOnly = true)
    //      text: text to be displayed
    //      isNumber: true if a number will be displayed here
    //      decimals: number only, number of decimals that are valied
    //      displayDecimals: number only, number of decimals on display only (default: 2)
    //      displayThousands: number only, show thousands separator on display only (default: yes)
    //      prefix: number only, prefix for display ex $
    //      suffix: number only, suffix for display ex %
    // 		  hidden: specify if the row needs to be shown or not. (default: 'false')
    // 		  hiddenOnField: If the field included in this attribute is false then the field is displayed. (default: '')
    // 		  hiddenOnValue: If the field specified on hiddenOnField has this value then the field is hidden (default: '')
    //  Divider:
    //    Creates a divider line plus a red arrow to go to the top of the page
    //  Group, GridGroup:
    //    Crates a gruop of fields to display as a Card element. (GridGroup will create a table inside the Group)
    //    Valid attributes:
    // 			className: class for the tag
    //      tableClassName: class for the table inside
    // 			EditOnly: false-> no, true -> yes (default false) if Yes the column will only be displayed during edit (displayOnly = false)
    // 			displayOnly: false-> no, true -> yes (default false) if Yes the column will not be displayed during edit (displayOnly = true)
    //      title: title of the Card
    // 		  hidden: specify if the row needs to be shown or not. (default: 'false')
    // 		  hiddenOnField: If the field included in this attribute is false then the field is displayed. (default: '')
    // 		  hiddenOnValue: If the field specified on hiddenOnField has this value then the field is hidden (default: '')
    // 	Button:
    // 		Creates a button that performs some action on click.
    // 		Valid attributes:
    // 			name: button name.
    // 			text: text inside the button.
    // 			onClick: action to perform on click.
    // 			EditOnly: false-> no, true -> yes (default false) if Yes the column will only be displayed during edit (displayOnly = false)
    // 			displayOnly: false-> no, true -> yes (default false) if Yes the column will not be displayed during edit (displayOnly = true)
    // 		  hidden: specify if the row needs to be shown or not. (default: 'false')
    // 		  hiddenOnField: If the field included in this attribute is false then the field is displayed. (default: '')
    // 		  hiddenOnValue: If the field specified on hiddenOnField has this value then the field is hidden (default: '')
    // 	*Section:
    // 		Creates a section title, line separator and optional help icon.
    // 		Valid attributes:
    // 			title: section title
    // 			includeSeparator: add a line separator before the title. (default: true)
    // 			helpID: if not empty a ? will be displayed on the left and a click will display this help ID.
    // 				(requires helpPage on DisplayOptions)
    // 			EditOnly: false-> no, true -> yes (default false) if Yes the column will only be displayed during edit (displayOnly = false)
    // 			displayOnly: false-> no, true -> yes (default false) if Yes the column will not be displayed during edit (displayOnly = true)
    // 		  hidden: specify if the row needs to be shown or not. (default: 'false')
    // 		  hiddenOnField: If the field included in this attribute is false then the field is displayed. (default: '')
    // 		  hiddenOnValue: If the field specified on hiddenOnField has this value then the field is hidden (default: '')
    // 	Grid:
    // 		Creates a GridControl subordinated to the Form.
    // 		Valid attributes:
    // 			field: field that contains the data to display in the grid
    //      title: Grid title displayed on top
    //      columns: text to specify how to display the columns in the grid
    // 			className: class for the tag
    //      initialStatus: 0 -> display, 1 -> edit, 3 -> add new
    //        (valid status: 0 display, 1 edit, 2 edit-modified, 3 new, 4 deleted, 5 added-deleted)
    // 		  AllowInsert: false -> no, true -> yes (default false)
    // 		  AllowDelete: false -> no, true -> yes (default false)
    // 		  displayOnly: false -> Edit is allowed, true -> only display is allowed (default false)
    //      classNameTitle: class of the title for the grid
    //      classNameHeaderRow: class of the row for Headers elements
    //      classNameRow: class of the row for column elements
    // 		  hidden: specify if the row needs to be shown or not. (default: 'false')
    // 		  hiddenOnField: If the field included in this attribute is false then the field is displayed. (default: '')
    // 		  hiddenOnValue: If the field specified on hiddenOnField has this value then the field is hidden (default: '')
    // 	RowField, Field:
    // 		(Both elements will display a field from the XML data, but RowField will create
    // 		 a row in the main table with 2 columns one for the name of the field (title attribute)
    // 		 and another for the field itself)
    // 		field: name of the field to be displayed on edit mode. That name should be an attribute on the input XML row element.
    // 		fieldType: types include: hidden (store value but column is invisible), label(display only), radio(radio button edit),
    //      text(text box edit), number(text box for number input), date(text box for date input), password, phone(text box for phone input), email(text box for email input),
    // 			checkbox(boolean edit with checkbox), listbox(choose from a list of options), link(creates a link to display more info about current row)
    //    className: class for each field (or input text)
    //    iconClassName: class for the icon if icon or iconpost are present
    //    wrapClassName: class for the inputGroup
    //    link: field that contains the URL to link to. (link fields only)
    // 		title: text use on the column header or the left side of the field.
    // 		*rightTitle: title on the right of the field.
    // 		*invertedValue: 'true' if value should be ON on false and OFF on true (checkbox only).
    //    *handleChange: function to handle change of data. (if not defined it will use the standard handleChange from the form)
    //    defaultValue: default value when you add an empty record.
    //    minVal: number only, minimum valid value
    //    maxVal: number only, maximum valid value
    //    increments: number only, ex. increments = 2 only even numbers are valid
    //    isNumber: (fieldType Label only) true if a number will be displayed here
    //    decimals: number only, number of decimals that are valied
    //    displayDecimals: number only, number of decimals on display only (default: 2)
    //    displayThousands: number only, show thousands separator on display only (default: yes)
    //    prefix: number only, prefix for display ex $
    //    suffix: number only, suffix for display ex %
    //    dateFormat: date only, format to display the date
    //    validateField: Function to execute before a change is detected. Parameters: FieldName, FieldValue, lastValue, Err. returns true if valid, false if not.
    //    modifyField: Function to execute after a change is detected. Parameters: FieldName and values.
    // 		*validateOnchange: form.validate will be called when the field is changed.
    //    includeInValues: true -> include in this.state.values to be send for save.
    // 		InsertOnly: false -> no, true -> yes (default false) if Yes the column will be display only on edit, but editable on new rows.
    // 		EditOnly: false -> no, true -> yes (default false) if Yes the column will only be displayed during edit (recordStatus = 1)
    // 		displayOnly: false -> no, true -> yes (default false) if Yes the column will not be displayed during edit (recordStatus = 0)
    // 		ReadOnly: The field will be shown as information only, no edit. (default: false)
    // 		ReadOnlyOnField: If the field included in this attribute is = to ReadOnlyOnValue then the field is ReadOnly (default: '')
    //    ReadOnlyOnValue: If the field specified on ReadOnlyOnField has this value then the field is ReadOnly (default: '')
    // 		disabled: specify if the field needs to be disabled. (default: 'false')
    // 		disabledOnField: If the field included in this attribute is true then the field is disabled (default: '')
    //    disabledOnValue: If the field specified on hiddenOnField has this value then the field is disabled (default: '')
    //    disabledOnFieldWithAnd: If the field is true the field will be disabled if All the fields on disabledOnField are true otherwise the field is disabled if any of the fields on disabledOnField are true (default: false)
    // 		*displayTest: Only for Checkbox field type, display Yes or No instead of the image.
    // 		*text: Only for href and checkbox field type, will indicate the text to display on each row.
    // 		*hrefid: Only for href field type, will indicate an id in case more than one href elem is defined by row.
    // 		*hrefif: Only for href field type, will indicate a field that if true, the field will be treated as label only.
    // 		*displayEmptyAs: What to show in display mode if the field is empty (only for Text and Label fields).
    // 		required: if true then a red * appears on the left on edit mode
    //    requiredOnField: If the field included in this attribute is false then the field is required. (default: '')
    // 	  requiredOnValue: If the field specified on requiredOnField has this value then the field is required (default: '')
    // 		*showRequired: if true then a red * appears on the left on edit mode
    // 		hidden: specify if the row needs to be shown or not. (default: 'false')
    //    hiddenOnField: If the field included in this attribute is false then the field is displayed. (default: '')
    // 	  hiddenOnValue: If the field specified on hiddenOnField has this value then the field is hidden (default: '')
    //    hiddenOnFieldWithAnd: If the field is true the field will be hidden if All the fields on hiddenOnField are true otherwise the field is hidden if any of the fields on hiddenOnField are true (default: false)
    //    hiddenOnFieldNoClear: If the field is hidden by a condition on hiddenOnField, then we automatically assign a null value. If this field is specified we omit the change of value.
    //      This will typically be null to avoid values on fields that are hidden.
    // 		*password: if not empty field will be treated as a password (text fields only)
    // 		helpID: if not empty a ? will be displayed on the left and a click will display this help ID.
    // 			(requires formHelpPage on DisplayOptions)

    // 		listbox and radio field types will require a collection of Option elements that will be use to determine the valid values for that field.
    // 		Option attributes:
    // 		id: ID value of the option.
    // 		name: name used for display.

    // 		Notes: If you overwrite the event onclick for checkbox or radio fields
    // 			or the onchange event for text or listbox fields,
    // 			then the javascript function used should call editXMLObjectField to update
    // 			the XML data with the data just entered on the field.
    // 	*MultipleSelect:
    // 		Creates 2 list of data (available and selected) and allows the user to move records between them.
    // 		The available list will be populated based on all Row elements on <Data/AvailableLists/{name}> ({name} is the name attribute)
    // 		Each Row should have 2 attributes ID and name.
    // 		The selected list will be populated based on all Row elements on <Data/Row/SelectedLists/{name}> ({name} is the name attribute)
    // 		Each Row should have at least one attribute ID. allowDelete attribute is optional and if 0 or False then the record cannot be deleted.
    // 		name: id of the field been modified. Determines the XML tags that will be used to populate the lists.
    // 		title: text used on the page.
    // 		listOrder (optional): Field name for the order field on the list, when is missing there is no order on the selected list.
    // 		invalidDeleteMessage: message in case delete is disable. The string %name% will be replace with the name of the object that is been deleted.
    // 	*SelectOrder:
    // 		Creates a list of data in which order is important, the list will allow for changes in the order of the items,
    // 		with 2 buttons at the side and a single item selection.
    // 		name: id of the field been modified. Determines the XML tags that will be used to populate the lists.
    // 		title: text used on the page.
    // 		listId: Field name for the ID on the list
    // 		listName: Field name for the text on the list
    // 		listOrder: Field name for the order field on the list
    //  *Buttons: if you are using the internal button you have to pass a flag to the save button if you are on participant mode from the admin

    this.getModifiedValues = this.getModifiedValues.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.displayError = this.displayError.bind(this);
    this.changeEditMode = this.changeEditMode.bind(this);
    this.getRequiredFieldsMissing = this.getRequiredFieldsMissing.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.saveValues = this.saveValues.bind(this);
    this.forcePaint = this.forcePaint.bind(this);
    this.updateGridSummary = this.updateGridSummary.bind(this);
    this.calculateFieldType = this.calculateFieldType.bind(this);
    this.handleSelectUnselect = this.handleSelectUnselect.bind(this);
    this.getReference = this.getReference.bind(this);
    this.collapseGroup = this.collapseGroup.bind(this);
    this.collapseAllGroups = this.collapseAllGroups.bind(this);
    this.isCollapsedGroup = this.isCollapsedGroup.bind(this);
    this.togglemodalOK = this.togglemodalOK.bind(this);
    this.togglemodalYesNo = this.togglemodalYesNo.bind(this);
    this.togglemodalOKClose = this.togglemodalOKClose.bind(this);

    this.numberOfRequiredFields = 0;
    this.modal = React.createRef();
    this.arrReferences = [];
    this.collapseGroups = [];

    this.modalkey = 10000000 + parseInt(Math.random() * 1000000);

    this.state = {
      firstTime: false,
      forcePaint: false,
      values: { ___recordStatus: 0 },
      //0 display, 1 edit, 2 edit-modified, 3 new, 4 deleted, 5 added-deleted
    };
  }

  collapseGroup(groupNumber) {
    this.collapseGroups[groupNumber] = !this.collapseGroups[groupNumber];
    this.setState({
      forcePaint: true,
    });
  }

  collapseAllGroups(collapse) {
    for (let i = 0; i < this.collapseGroups.length; i++) {
      this.collapseGroups[i] = collapse;
    }
    this.setState({
      forcePaint: true,
    });
  }

  isCollapsedGroup(groupNumber) {
    if (this.collapseGroups.length < groupNumber + 1) {
      while (this.collapseGroups.length < groupNumber + 1) {
        this.collapseGroups.push(false);
      }
    }
    return this.collapseGroups[groupNumber];
  }

  getReference(name) {
    let item = this.arrReferences.filter((x) => x.name == name);
    return item.length == 0 ? null : item[0].reference;
  }

  createReference(name) {
    let itemRef = this.getReference(name);
    if (itemRef == null) {
      itemRef = React.createRef();
      let item = { name: name, reference: itemRef };
      this.arrReferences.push(item);
    }
    return itemRef;
  }

  togglemodalOK(strHeader, strBody) {
    this.modal.current.togglemodalOK(strHeader, strBody);
  }

  togglemodalOKClose(strHeader, strBody, closeFunction) {
    this.modal.current.togglemodalOKClose(strHeader, strBody, closeFunction);
  }

  togglemodalYesNo(strHeader, strBody, okFunction) {
    this.modal.current.togglemodalYesNo(strHeader, strBody, okFunction);
  }

  addDivider(hidden, center) {
    if (hidden === undefined) hidden = false;
    return (
      <Row
        style={{ height: "25px" }}
        className="input-group p-0 m-0"
        hidden={hidden}
      >
        {center ? (
          <div
            className="dividerLine"
            style={{
              borderTop: "1px solid gray",
              width: "96%",
              marginTop: "12px",
              marginLeft: "2%",
            }}
          />
        ) : (
          <div
            className="dividerLine"
            style={{
              borderTop: "1px solid gray",
              width: "100%",
              marginTop: "12px",
              marginLeft: "0%",
            }}
          />
        )}
        <div>
          <a
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <img src="img/arrow_up.gif" border="0" />
          </a>
        </div>
      </Row>
    );
  }

  forcePaint() {
    this.setState({
      forcePaint: true,
    });
  }

  findValue(field) {
    let value = this.props.data[field];
    if (
      this.state.values.___recordStatus > 0 &&
      this.state.values[field] !== undefined
    ) {
      value = this.state.values[field];
    }
    return value;
  }

  checkAvailability(arr, val) {
    return arr.some(function (arrVal) {
      return val == arrVal;
    });
  }

  checkArrayPos(arr, val) {
    let i;
    let resp = -1;
    for (i = 0; i < arr.length && resp == -1; i++) {
      if (Array.isArray(arr[i])) {
        //each element is an array
        if (this.checkAvailability(arr[i], val)) resp = i;
      } else {
        //each element is not an array
        if (arr[i] == val) resp = i;
      }
    }
    if (resp == -1) resp = 0;
    return resp;
  }

  numberOfDecimals(expression) {
    let j;
    let i = 0;
    let blnHasDecimals = false;
    let c; // A single character.
    let strAux = expression.toString();

    for (j = 0; j < strAux.length; j++) {
      // Look at all characters.
      c = strAux.charAt(j); // Look at each character.
      if (c >= "0" && c <= "9") {
        if (blnHasDecimals) {
          i++;
        }
      }
      if (c == ".") {
        // Decimal point?
        if (!blnHasDecimals) {
          // If we haven't seen one already,
          blnHasDecimals = true; // now we have.
        }
      }
    }
    return i;
  }

  calculateFieldType(x) {
    let fieldType = x.fieldType;
    let fieldTypeOnField =
      x.fieldTypeOnField === undefined ? "" : x.fieldTypeOnField;
    let fieldTypeOnValue =
      x.fieldTypeOnValue === undefined ? true : x.fieldTypeOnValue;
    if (fieldTypeOnField != "") {
      let value2 = this.findValue(fieldTypeOnField);
      let intPos = this.checkArrayPos(fieldTypeOnValue, value2);
      fieldType = x.fieldType[intPos];
    }
    return fieldType;
  }

  getModifiedValues(includeValidGridRows, includeAllGridRows) {
    if (includeValidGridRows === undefined) includeValidGridRows = false;
    if (includeAllGridRows === undefined) includeAllGridRows = false;
    let values = this.state.values;
    this.props.display.map((x) => {
      this.getGridValues(x, includeValidGridRows, includeAllGridRows, values);
    });
    return values;
  }

  IsdisplayOnly(displayOnly) {
    return displayOnly === undefined ? false : displayOnly;
  }

  getGridValues(record, includeValidGridRows, includeAllGridRows, values) {
    if (this.props.initialStatus == 2) this.includeValidRows = true;
    this.includeValidRows =
      record.includeValidRows !== undefined
        ? record.includeValidRows
        : includeValidGridRows;
    let includeAllRows =
      record.includeAllRows !== undefined
        ? record.includeAllRows
        : includeAllGridRows;
    let displayOnly = this.IsdisplayOnly(record.displayOnly);

    if (record.tag == "Grid" && !displayOnly) {
      values[record.field] = this.getReference(
        this.props.name + "Grid___" + record.field
      ).current.getModifiedValues(this.includeValidRows, includeAllRows);
    } else if (record.display !== undefined) {
      record.display.map((y) => {
        this.getGridValues(y, includeValidGridRows, includeAllGridRows, values);
      });
    }
  }

  findField(record, fieldName, result) {
    if (
      record.tag == "GridRowField" ||
      record.tag == "RowField" ||
      record.tag == "RowField2" ||
      record.tag == "GridColField" ||
      record.tag == "Field"
    ) {
      if (record.field == fieldName) {
        result.obj = record;
      }
    } else if (
      record.tag == "Grid" &&
      record.isGridSelect
    ) {
      record.display.map((y) => {
        this.findField(y, fieldName, result);
      });
    } else if (record.tag == "Grid") {
      record.columns.map((y) => {
        this.findField(y, fieldName, result);
      });
    } else if (record.display !== undefined) {
      record.display.map((y) => {
        this.findField(y, fieldName, result);
      });
    }
  }

  findRecordField(fieldName) {
    let result = { obj: null };
    this.props.display.map((y) => {
      this.findField(y, fieldName, result);
    });
    return result.obj;
  }

  findValues(editMode, record, values) {
    let includeInValues =
      record.includeInValues === undefined ? false : record.includeInValues;
    let displayOnly = this.IsdisplayOnly(record.displayOnly);

    if (
      (record.tag == "GridRowField" ||
        record.tag == "RowField" ||
        record.tag == "RowField2" ||
        record.tag == "GridColField" ||
        record.tag == "Field") &&
      ((this.calculateFieldType(record) != "label" && !displayOnly) ||
        includeInValues == true)
    ) {
      if (editMode == 1 || editMode == 2 || editMode == 4) {
        values[record.field] = this.props.data[record.field];
      } else {
        values[record.field] =
          record.defaultValue === undefined ? null : record.defaultValue;
      }
    } else if (record.tag == "Grid") {
      if (editMode == 1 || editMode == 2 || editMode == 4) {
        values[record.field] =
          this.props.data[record.field] == null
            ? []
            : this.props.data[record.field];
      } else {
        values[record.field] = [];
      }
    } else if (record.display !== undefined) {
      record.display.map((y) => {
        this.findValues(editMode, y, values);
      });
    }
  }

  findRequiredByTag(record, rowDescription, out) {
    let displayOnly = this.IsdisplayOnly(record.displayOnly);
    let value=null;
    let isRequired = record.required === undefined ? false : record.required;
    let requiredOnField =
      record.requiredOnField === undefined ? "" : record.requiredOnField;
    let requiredOnValue =
      record.requiredOnValue === undefined ? true : record.requiredOnValue;
    let hidden = record.hidden === undefined ? false : record.hidden;
    let hiddenOnFieldWithAnd =
      record.hiddenOnFieldWithAnd === undefined
        ? false
        : record.hiddenOnFieldWithAnd;
    let hiddenOnField =
      record.hiddenOnField === undefined ? "" : record.hiddenOnField;
    let hiddenOnValue =
      record.hiddenOnValue === undefined ? true : record.hiddenOnValue;
    let gridErrors = "";
    if (hiddenOnField != "") {
      let {isHidden}=this.handleHiddenonFieldFunc(hiddenOnField,hiddenOnValue,hidden,hiddenOnFieldWithAnd);
      hidden=isHidden;
    }
    if (requiredOnField !== "") {
      const {isRequire}= this.handleRequiredFun(requiredOnField,requiredOnValue,isRequired);
      isRequired=isRequire;
    }
    let fieldType = this.calculateFieldType(record);
    if (
      (record.tag == "GridRowField" ||
        record.tag == "RowField" ||
        record.tag == "RowField2" ||
        record.tag == "GridColField" ||
        record.tag == "Field") &&
      fieldType != "label" &&
      !displayOnly &&
      !hidden &&
      isRequired
    ) {
      if (
        this.state.values[record.field] === undefined ||
        this.state.values[record.field] === null ||
        this.state.values[record.field] === "" ||
        (this.state.values[record.field] == NullRecord &&
          fieldType == "select") ||
        (this.state.values[record.field] == NullRecord &&
          fieldType == "datalist")
      ) {
        if (out.msg != "") out.msg += ", ";
        out.msg += record.title.replace(":", "");
        if (rowDescription !== undefined && rowDescription != "")
          out.msg += rowDescription;
      }
    } else if (record.tag == "Grid" && !displayOnly && !hidden) {
      gridErrors = this.getReference(
        this.props.name + "Grid___" + record.field
      ).current.getRequiredFieldsMissing(false);
      if (gridErrors != "") {
        if (out.msg != "") out.msg += ", ";
        out.msg += gridErrors;
      }
    } else if (record.display !== undefined) {
      record.display.map((y) => {
        this.findRequiredByTag(y, rowDescription, out);
      });
    }
  }

  getRequiredFieldsMissing(rowDescription) {
    // create values record
    let outMsg = { msg: "" };
    this.props.display.map((x) => {
      this.findRequiredByTag(x, rowDescription, outMsg);
    });
    return outMsg.msg;
  }

  changeGridMode(record, editMode) {
    let displayOnly = this.IsdisplayOnly(record.displayOnly);
    if (record.tag == "Grid" && !displayOnly) {
      this.getReference(
        this.props.name + "Grid___" + record.field
      ).current.changeEditMode(editMode);
    } else if (record.display !== undefined) {
      record.display.map((y) => {
        this.changeGridMode(y, editMode);
      });
    }
  }

  changeEditMode(editMode) {
    if (editMode >= 1 && editMode <= 4) {
      // create values record
      let values = { ___recordStatus: editMode };
      if (this.props.gridID !== undefined) {
        values.___id = this.props.gridID;
      }
      this.props.display.map((x) => {
        this.changeGridMode(x, 1);
      });
      this.props.display.map((x) => {
        this.findValues(editMode, x, values);
      });

      this.setState({
        values: values,
      });
    } else {
      if (editMode == 0) {
        this.props.display.map((x) => {
          this.changeGridMode(x, 0);
        });
      }
      this.setState({
        values: { ___recordStatus: editMode },
      });
    }
  }

  setValues(record, values) {
    let includeInValues =
      record.includeInValues === undefined ? false : record.includeInValues;
    let displayOnly = this.IsdisplayOnly(record.displayOnly);

    if (record.tag == "Grid" && !displayOnly) {
      this.props.data[record.field] = this.getReference(
        this.props.name + "Grid___" + record.field
      ).current.saveValues(values[record.field]);
    } else if (
      (record.tag == "GridRowField" ||
        record.tag == "RowField" ||
        record.tag == "RowField2" ||
        record.tag == "GridColField" ||
        record.tag == "Field") &&
      ((this.calculateFieldType(record) != "label" && !displayOnly) ||
        includeInValues)
    ) {
      this.props.data[record.field] = values[record.field];
    } else if (record.display !== undefined) {
      record.display.map((y) => {
        this.setValues(y, values);
      });
    }
  }

  saveValues(values) {
    this.props.display.map((x) => {
      this.setValues(x, values);
    });
    let initialStatus =
      this.props.initialStatus !== undefined ? this.props.initialStatus : 0;
    if (initialStatus == 0) {
      values = { ___recordStatus: 0 };
    } else if (initialStatus == 2 && this.state.values.___recordStatus == 1) {
      values.___recordStatus = 1;
    } else {
      values.___recordStatus = initialStatus;
    }
    this.setState({
      values: values,
      forcePaint: true,
    });
  }

  validateForm() {
    let errorMsg = this.getRequiredFieldsMissing();
    if (errorMsg != "") {
      errorMsg = "The following required fields are missing: " + errorMsg;
    }
    return errorMsg;
  }

  displayError(name, value, message) {
    //Add validations here for Percent IN
    this.togglemodalOK("Failed Validation", message);
  }

  handleChange(name, value, lastValue, err, file, result) {
    //Identify which value is changing;
    let recordStatus = this.state.values.___recordStatus;
    let fieldName = name.substring(name.lastIndexOf("___") + 3);
    let values = this.state.values;
    let objField = this.findRecordField(fieldName);
    let fieldType = this.calculateFieldType(objField);
    let validateField =
      objField.validateField !== undefined ? objField.validateField : null;
    let modifyField =
      objField.modifyField !== undefined ? objField.modifyField : null;
    //Only for file case
    if (
      this.props.fileUpload !== undefined &&
      file !== undefined &&
      file != null
    ) {
      this.props.fileUpload(file);
    }
    //call to optional validation function
    if (validateField != null) {
      if (!validateField(name, value, lastValue, err)) return false;
    }

    if (recordStatus == 1) {
      values.___recordStatus = 2;
    }
    values[fieldName] = value;
    //update summary if necessary
    if (
      fieldType == "number" &&
      objField.summaryType !== undefined &&
      this.props.updateGridSummary !== undefined
    ) {
      this.props.updateGridSummary(objField.field, lastValue, value);
    }
   // call to optional modify function
    if (modifyField != null) {
      modifyField(name, values);
    }
    this.setState({
      values: values,
    });
    return true;
  }

  handleSelectUnselect(name, value, lastValue, err) {
    //Identify which value is changing;
    let fieldName = name.substring(name.lastIndexOf("___") + 3);
    if (this.props.handleChangeSelectUnselect !== undefined) {
      this.props.handleChangeSelectUnselect(fieldName, value);
    }
    return true;
  }
handleHiddenonFieldFunc(hiddenOnField,hiddenOnValue,hidden,hiddenOnFieldWithAnd){
  if (Array.isArray(hiddenOnField)) {
    if (hiddenOnFieldWithAnd) {
      let i;
      for (i = 0; i < hiddenOnValue.length; i++) {
        let hiddenRow = true;
        let j;
        for (j = 0; j < hiddenOnField.length; j++) {
          let value = this.findValue(hiddenOnField[j]);
          if (Array.isArray(hiddenOnValue[i][j])) {
            hiddenRow =
              hiddenRow &&
              this.checkAvailability(hiddenOnValue[i][j], value);
          } else {
            hiddenRow = hiddenRow && value == hiddenOnValue[i][j];
          }
        }
        hidden = hidden || hiddenRow;
      }
    } else {
      let i;
      for (i = 0; i < hiddenOnField.length; i++) {
        let value = this.findValue(hiddenOnField[i]);
        if (Array.isArray(hiddenOnValue[i])) {
          hidden =
            hidden || this.checkAvailability(hiddenOnValue[i], value);
        } else {
          hidden = hidden || value == hiddenOnValue[i];
        }
      }
    }
  } else {
     let value = this.findValue(hiddenOnField);
    if (Array.isArray(hiddenOnValue)) {
      hidden = hidden || this.checkAvailability(hiddenOnValue, value);
    } else {
      hidden = hidden || value == hiddenOnValue;
    }
  }
  return {isHidden:hidden};
}
  updateGridSummary(field, oldValue, newValue) {
    let objField = this.getReference(
      this.props.tableName + field + "Summary"
    ).current;
    let value = objField.state.value;
    value = Number(value) + Number(newValue) - Number(oldValue);
    objField.setState({
      value: value,
    });
  }

  componentDidMount() {
    this.setState({ firstTime: true });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props || prevState !== this.state) {
      if (this.props.data != prevProps.data && this.props.initialStatus >= 1) {
        this.changeEditMode(this.props.initialStatus);
        return false;
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps !== this.props || nextState !== this.state) {
      if (!this.state.firstTime  && nextState.firstTime) {
        if (
          nextProps.initialStatus >= 1 &&
          nextState.values.___recordStatus == 0
        ) {
          this.changeEditMode(nextProps.initialStatus);
          return false;
        }
      } else if (
        this.props.initialStatus != nextProps.initialStatus &&
        nextProps.initialStatus != nextState.values.___recordStatus
      ) {
        this.changeEditMode(nextProps.initialStatus);
        return false;
      }
      if (nextState.forcePaint) {
        this.setState({ forcePaint: false });
        return false;
      }

      return true;
    }
    return false;
  }

  createHelpLink(helpPage, helpID, size, addSpaceBefore, addSpaceAfter) {
    return (
      <React.Fragment>
        {addSpaceBefore && (
          <React.Fragment>
            {"\u00A0"}
            {"\u00A0"}
          </React.Fragment>
        )}
        <HelpControl
          helpPage={helpPage}
          helpID={helpID}
          size={size}
          API_TOKEN={this.props.API_TOKEN}
          CLASSIC_URL={this.props.CLASSIC_URL}
          encrypt={this.props.encrypt}
        />
        {addSpaceAfter && (
          <React.Fragment>
            {"\u00A0"}
            {"\u00A0"}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
  handleRequiredFun(requiredOnField,requiredOnValue,isRequired){
   if (Array.isArray(requiredOnField)) {
        //requiredOnField is an array
        isRequired = true;
        let i;
        for (i = 0; i < requiredOnField.length; i++) {
          let value = this.findValue(requiredOnField[i]);
          if (Array.isArray(requiredOnValue[i])) {
            isRequired =
              isRequired && this.checkAvailability(requiredOnValue[i], value);
          } else {
            isRequired = isRequired && value == requiredOnValue[i];
          }
        }
      } else {
        //requiredOnField is a string with the name of the field
        let value = this.findValue(requiredOnField);
        if (Array.isArray(requiredOnValue)) {
          isRequired =
            isRequired || this.checkAvailability(requiredOnValue, value);
        } else {
          isRequired = isRequired || value == requiredOnValue;
        }
      }
      return {
       isRequire:isRequired
      };
}
  processTag(x) {
    let className = x.className !== undefined ? x.className : "";
    let classNameEdit =
      x.classNameEdit !== undefined ? x.classNameEdit : className;
    if (this.state.values.___recordStatus > 0) className = classNameEdit;
    let classNameTable =
      x.className !== undefined
        ? x.className
        : "table table-sm table-bordered table-striped table-responsive"; //table-fitcontent
    if (this.state.values.___recordStatus > 0) classNameTable = classNameEdit;
    let InsertOnly = x.InsertOnly !== undefined ? x.InsertOnly : false;
    let EditOnly = x.EditOnly !== undefined ? x.EditOnly : false;
    let displayOnly = this.IsdisplayOnly(x.displayOnly);

    let hideMark =
      this.props.hideRequiredMark !== undefined
        ? this.props.hideRequiredMark
        : false;
    let isRequired = x.required === undefined ? false : x.required;
    let requiredOnField =
      x.requiredOnField === undefined ? "" : x.requiredOnField;
    let requiredOnValue =
      x.requiredOnValue === undefined ? true : x.requiredOnValue;
    if (requiredOnField !== "") {
     let {isRequire}= this.handleRequiredFun(requiredOnField,requiredOnValue,isRequired);
     isRequired=isRequire;
    }

    let ReadOnly = x.ReadOnly !== undefined ? x.ReadOnly : false;
    let ReadOnlyOnField =
      x.ReadOnlyOnField === undefined ? "" : x.ReadOnlyOnField;
    let ReadOnlyOnValue =
      x.ReadOnlyOnValue === undefined ? true : x.ReadOnlyOnValue;
    if (ReadOnlyOnField != "") {
      let onField = this.state.values[ReadOnlyOnField];
      if (onField === undefined) onField = this.props.data[ReadOnlyOnField];
      if (Array.isArray(ReadOnlyOnValue)) {
        ReadOnly = ReadOnly || this.checkAvailability(ReadOnlyOnValue, onField);
      } else {
        ReadOnly = ReadOnly || onField == ReadOnlyOnValue;
      }
    }
    let editModeOn =
      (this.state.values.___recordStatus == 1 ||
        this.state.values.___recordStatus == 2 ||
        this.state.values.___recordStatus == 3) &&
      !ReadOnly;
    let hidden = x.hidden === undefined ? false : x.hidden;
    let hiddenOnFieldWithAnd =
      x.hiddenOnFieldWithAnd === undefined ? false : x.hiddenOnFieldWithAnd;
    let hiddenOnField = x.hiddenOnField === undefined ? "" : x.hiddenOnField;
    let hiddenOnValue = x.hiddenOnValue === undefined ? true : x.hiddenOnValue;
    let fieldType = this.calculateFieldType(x);
    if (hiddenOnField != "" && x.hiddenOnValue !== undefined) {
      let {isHidden}=this.handleHiddenonFieldFunc(hiddenOnField,hiddenOnValue,hidden,hiddenOnFieldWithAnd);
      hidden=isHidden;
    }
    if (
      !(
        (this.state.values.___recordStatus == 0 && EditOnly) ||
        (this.state.values.___recordStatus > 0 && displayOnly) ||
        (this.state.values.___recordStatus != 3 && InsertOnly)
      )
    ) {
      let classNameRow =
        this.props.classNameRow !== undefined
          ? this.props.classNameRow
          : "row-padding";
      if (x.classNameRow !== undefined) classNameRow += " " + x.classNameRow;
      let className1 =
        this.props.className1 !== undefined
          ? this.props.className1
          : "col-6 deferral-label";
      let className2 =
        this.props.className2 !== undefined
          ? this.props.className2
          : "col-6 deferral-field";

      let helpID = x.helpID !== undefined ? x.helpID : -1;
      let isCollapsed = false;
      switch (x.tag) {
        case "GridRowField":
        case "RowField":
          let tableDisplay =
            this.props.tableDisplay !== undefined
              ? this.props.tableDisplay
              : false;
          if (tableDisplay || x.tag == "GridRowField" || x.isTable) {
            return (
              <tr
                className={classNameRow}
                hidden={hidden || fieldType == "hidden"}
              >
                <td className={className1}>
                  <div>
                    {helpID != -1 &&
                      this.createHelpLink(
                        this.props.formHelpPage,
                        helpID,
                        "lg",
                        false,
                        true
                      )}
                    {editModeOn && !hideMark && isRequired && (
                      <font color="red">*{"\u00A0"}</font>
                    )}
                    {x.title}
                  </div>
                </td>
                {/* If innerTableRadioField = true we don't need to create <td> because the radiobutton will create it */}
                {x.innerTableRadioField !== undefined &&
                x.innerTableRadioField == true ? (
                  this.createField(x, true, isRequired, ReadOnly, hidden)
                ) : (
                  <td className={className2}>
                    {this.createField(x, true, isRequired, ReadOnly, hidden)}
                  </td>
                )}
              </tr>
            );
          } else {
            return (
              <Row
                className={classNameRow}
                hidden={hidden || fieldType == "hidden"}
              >
                <Col className={className1}>
                  <div>
                    {helpID != -1 &&
                      this.createHelpLink(
                        this.props.formHelpPage,
                        helpID,
                        "lg",
                        false,
                        true
                      )}
                    {editModeOn && !hideMark && isRequired && (
                      <font color="red">*{"\u00A0"}</font>
                    )}
                    {x.title}
                  </div>
                </Col>
                <Col className={className2}>
                  {this.createField(x, true, isRequired, ReadOnly, hidden)}
                </Col>
              </Row>
            );
          }
        case "RowField2":
          let classNameRow1 =
            this.props.classNameRow1 !== undefined
              ? this.props.classNameRow1
              : "row-padding";
          let classNameRow2 =
            this.props.classNameRow2 !== undefined
              ? this.props.classNameRow2
              : "row-padding";
          return (
            <React.Fragment>
              <Row className={classNameRow1}>
                <Col className={className1}>
                  {<div> {x.title} </div>}
                  {editModeOn && !hideMark && isRequired && (
                    <font color="red">*</font>
                  )}
                  {helpID != -1 &&
                    this.createHelpLink(
                      this.props.formHelpPage,
                      helpID,
                      "lg",
                      false,
                      true
                    )}
                </Col>
              </Row>
              <Row className={classNameRow2}>
                <Col className={className2}>
                  {this.createField(x, true, isRequired, ReadOnly, hidden)}
                </Col>
              </Row>
            </React.Fragment>
          );

        case "GridColField":
          return (
            <td
              hidden={hidden || fieldType == "hidden"}
              className={x.classNameCol !== undefined ? x.classNameCol : ""}
              colSpan={x.colspan !== undefined ? x.colspan : "1"}
              align={x.align}
            >
              {this.createField(x, hideMark, isRequired, ReadOnly, hidden)}
              {helpID != -1 &&
                this.createHelpLink(
                  this.props.formHelpPage,
                  helpID,
                  "lg",
                  true,
                  false
                )}
            </td>
          );
        case "Field":
          return (
            //The validation below was added to show the field depending on a specific class or a default
            <div
              className={x.divClassName}
              hidden={hidden || fieldType == "hidden"}
            >
              {this.createField(x, hideMark, isRequired, ReadOnly, hidden)}
              {helpID != -1 &&
                this.createHelpLink(
                  this.props.formHelpPage,
                  helpID,
                  "lg",
                  true,
                  false
                )}
            </div>
          );

        case "Group":
          return (
            <Card hidden={hidden}>
              <CardHeader>
                <div className="displayFlex">
                  {x.title}
                  {helpID != -1 &&
                    this.createHelpLink(
                      this.props.formHelpPage,
                      helpID,
                      "lg",
                      true,
                      false
                    )}
                </div>
              </CardHeader>
              <CardBody className={className}>
                <div
                  className={
                    x.tableClassName === undefined ? "table" : x.tableClassName
                  }
                >
                  {x.display.map((y) => {
                    return this.processTag(y);
                  })}
                </div>
              </CardBody>
            </Card>
          );
        case "MessageRight":
          return (
            <Row
              className={classNameRow}
              hidden={hidden || fieldType == "hidden"}
            >
              <Col className={className1}></Col>
              <Col className={className2}>
                <div className={className}>
                  <div dangerouslySetInnerHTML={{ __html: x.text }} />
                  {helpID != -1 &&
                    this.createHelpLink(
                      this.props.formHelpPage,
                      helpID,
                      "lg",
                      true,
                      false
                    )}
                </div>
              </Col>
            </Row>
          );
        case "InnerTableRadioField":
          return (
            <Row hidden={hidden} className={"row-padding row-center m-0"}>
              <Card>
                <CardBody className="card-borderless p-0">
                  <Table
                    className={
                      x.tableClassName === undefined
                        ? "table-sm table-bordered table-striped table-responsive"
                        : x.tableClassName
                    }
                  >
                    <thead
                      className={
                        x.classNameHead === undefined
                          ? "bg-darkblue"
                          : x.classNameHead
                      }
                    >
                      <tr
                        //hidden={this.state.values.___recordStatus == 0}
                        className={classNameRow}
                      >
                        <th
                          className={
                            x.className1 !== undefined
                              ? x.className1
                              : className1
                          }
                        ></th>
                        {x.itemList.map((y) => {
                          return (
                            <th
                              className={
                                x.className2 !== undefined
                                  ? x.className2
                                  : className2
                              }
                            >
                              {y.text}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {x.display.map((y) => {
                        y.innerTableRadioField = true;
                        y.isTable = true;
                        y.itemList = x.itemList;
                        return this.processTag(y);
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Row>
          );
        case "GridGroup":
          if (x.collapsable !== undefined && x.collapsable) {
            isCollapsed = this.isCollapsedGroup(x.groupNumber);
          }
          let collapsable = x.collapsable !== undefined && x.collapsable;
          let hideDivider = x.hideDivider !== undefined ? x.hideDivider : false;
          let classNameGroup =
            x.className !== undefined ? x.className : "gridgroup-padding";
          return (
            <Card hidden={hidden}>
              <CardBody className="gridgroup-padding">
                {/*The validation below was added cuz in some cases the grid was no showed in right way*/}
                {!collapsable && (
                  <Row>
                    <Col
                      className={
                        (x.classNameTitle !== undefined
                          ? x.classNameTitle
                          : "docSectionTitle") +
                        " displayFlex " +
                        (collapsable ? "col-10" : "col-12")
                      }
                    >
                      {x.title}
                      {helpID != -1 &&
                        this.createHelpLink(
                          this.props.formHelpPage,
                          helpID,
                          "lg",
                          true,
                          false
                        )}
                    </Col>
                  </Row>
                )}
                {collapsable && (
                  <Row>
                    <Col
                      className={
                        (x.classNameTitle !== undefined
                          ? x.classNameTitle
                          : "docSectionTitle") +
                        " displayFlex " +
                        (collapsable ? "col-10" : "col-12")
                      }
                    >
                      <a
                        className="message-link"
                        onClick={() => {
                          this.collapseGroup(x.groupNumber);
                        }}
                      >
                        {x.title}
                      </a>
                      {helpID != -1 &&
                        this.createHelpLink(
                          this.props.formHelpPage,
                          helpID,
                          "lg",
                          true,
                          false
                        )}
                    </Col>
                    <Col className="text-right col-2">
                      <a
                        className="message-link"
                        onClick={() => {
                          this.collapseGroup(x.groupNumber);
                        }}
                      >
                        {" "}
                        {!isCollapsed ? "Hide Group " : "Show Group "}
                        <Icon
                          name={
                            !isCollapsed
                              ? "keyboard_arrow_up"
                              : "keyboard_arrow_down"
                          }
                          size="md"
                        />
                      </a>
                    </Col>
                  </Row>
                )}
                <Row className={classNameGroup} hidden={isCollapsed}>
                  <Col>
                    {x.display.map((y) => {
                      return this.processTag(y);
                    })}
                  </Col>
                </Row>
                {this.addDivider(isCollapsed || hideDivider, !collapsable)}
              </CardBody>
            </Card>
          );
        case "Divider":
          return this.addDivider(this.props.hidden, true);
        case "Div":
          return (
            <div className={className} hidden={hidden}>
              {x.display.map((y) => {
                return this.processTag(y);
              })}
            </div>
          );
        case "Table":
          className =
            x.className !== undefined
              ? x.className
              : "table table-sm table-bordered table-responsive table-striped"; //table-fitcontent
          return (
            <Table className={className} hidden={hidden}>
              {x.display.map((y) => {
                return this.processTag(y);
              })}
            </Table>
          );
        case "RowEmpty":
          return (
            <Row hidden={hidden}>
              <Col>
                <br />
              </Col>
            </Row>
          );
        case "Row":
          let classNameRow3 =
            x.className !== undefined ? x.className : "row-padding";
          return (
            <Row className={classNameRow3} hidden={hidden}>
              {x.display.map((y) => {
                return this.processTag(y);
              })}
            </Row>
          );
        case "Col":
          return (
            <Col className={className} hidden={hidden}>
              {x.display.map((y) => {
                return this.processTag(y);
              })}
            </Col>
          );
        case "RowCol":
          return (
            <Row className={classNameRow} hidden={hidden}>
              <Col className={className}>
                {x.display.map((y) => {
                  return this.processTag(y);
                })}
              </Col>
            </Row>
          );
        case "GridTable":
          return (
            <Card>
              <CardBody className="card-borderless p-0">
                <Row className="m-0">
                  <table className={classNameTable} hidden={hidden}>
                    {x.display.map((y) => {
                      return this.processTag(y);
                    })}
                  </table>
                </Row>
              </CardBody>
            </Card>
          );
        case "GridBody":
          return (
            <tbody className={className} hidden={hidden}>
              {x.display.map((y) => {
                return this.processTag(y);
              })}
            </tbody>
          );
        case "GridRow":
          let classNameRow5 =
            x.className !== undefined ? x.className : "row-padding";
          return (
            <tr
              className={classNameRow5}
              hidden={hidden}
              //key={"GridRow" + this.props.name}
            >
              {x.display.map((y) => {
                return this.processTag(y);
              })}
            </tr>
          );
        case "GridRowHeader":
          classNameRow =
            x.classNameRow !== undefined ? x.classNameRow : "row-padding";
          let classNameHead =
            x.className !== undefined ? x.className : "bg-darkblue";
          return (
            <thead className={classNameHead}>
              <tr
                className={classNameRow}
                hidden={hidden}
                //key={"GridRow" + this.props.name}
              >
                {x.display.map((y) => {
                  return this.processTag(y);
                })}
              </tr>
            </thead>
          );
        case "GridColHeader":
          return (
            <th
              className={className}
              hidden={hidden}
              colSpan={x.colspan !== undefined ? x.colspan : "1"}
            >
              {x.display === undefined ? (
                <React.Fragment>
                  {x.bold === undefined || x.bold == false ? (
                    x.isHTML !== undefined ? (
                      parse(x.text)
                    ) : (
                      x.text
                    )
                  ) : (
                    <b>{x.isHTML !== undefined ? parse(x.text) : x.text}</b>
                  )}
                  {helpID != -1 &&
                    this.createHelpLink(
                      this.props.formHelpPage,
                      helpID,
                      "lg",
                      false,
                      true
                    )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {x.display.map((y) => {
                    return this.processTag(y);
                  })}
                </React.Fragment>
              )}
            </th>
          );
        case "GridCol":
          return (
            <td
              className={className}
              hidden={hidden}
              colSpan={x.colspan !== undefined ? x.colspan : "1"}
              align={x.align}
            >
              {x.display.map((y) => {
                return this.processTag(y);
              })}
            </td>
          );
        case "GridColLabel":
          return (
            <td
              className={x.classNameCol !== undefined ? x.classNameCol : ""}
              colSpan={x.colspan !== undefined ? x.colspan : "1"}
              align={x.align}
              hidden={hidden}
            >
              {x.bold === undefined || x.bold == false ? (
                x.isHTML !== undefined ? (
                  parse(x.text)
                ) : (
                  x.text
                )
              ) : (
                <b>{x.isHTML !== undefined ? parse(x.text) : x.text}</b>
              )}
            </td>
          );
        case "GridRowCol":
          return (
            <tr className={classNameRow} hidden={hidden}>
              <td className={className}>
                {x.display.map((y) => {
                  return this.processTag(y);
                })}
              </td>
            </tr>
          );
        case "Link":
          return (
            <div>
              {x.icon !== undefined && <Icon name={x.icon} />}
              {x.onClick !== undefined ? (
                <Button
                  className="button-to-link2"
                  onClick={() => x.onClick(this)}
                >
                  {x.text}
                </Button>
              ) : (
                <a className="nav-link-2" target="_blank" href={x.link}>
                  {x.text}
                </a>
              )}
              {x.iconpost !== undefined && <Icon name={x.iconpost} />}
            </div>
          );
        case "Button":
          let classNameButton =
            x.className !== undefined ? x.className : "float-left";
          return (
            <Button
              className={classNameButton}
              hidden={hidden}
              disabled={x.disabled}
              color={x.color !== undefined ? x.color : "primary"}
              onClick={() => {
                x.onClick(this);
              }}
            >
              {x.icon !== undefined && <Icon name={x.icon} />}
              {x.text !== undefined &&
                x.text != "" &&
                "\u00A0" + "\u00A0" + x.text + "\u00A0" + "\u00A0"}
            </Button>
          );
        case "Label":
          if (x.isNumber !== undefined || x.isNumber) {
            let decimals =
              x.displayDecimals === undefined
                ? x.decimals === undefined
                  ? 2
                  : x.decimals
                : x.displayDecimals;
            return (
              <div>
                {helpID != -1 &&
                  this.createHelpLink(
                    this.props.formHelpPage,
                    helpID,
                    "lg",
                    false,
                    true
                  )}
                {x.icon !== undefined && <Icon name={x.icon} />}
                <FormatNumberControl
                  ref={this.createReference(x.id)}
                  value={x.text}
                  displayType={"text"}
                  fixedDecimalScale={decimals == -1 ? false : true}
                  decimalScale={decimals == -1 ? null : decimals}
                  thousandSeparator={
                    x.displayThousands === undefined ? true : x.displayThousands
                  }
                  prefix={x.prefix === undefined ? "" : x.prefix}
                  suffix={x.suffix === undefined ? "" : x.suffix}
                />
                {x.iconpost !== undefined && <Icon name={x.iconpost} />}
              </div>
            );
          } else if (x.titleCheckBox) {
            if (this.state.values.___recordStatus < 1) {
              return (
                <div hidden={hidden} className={className}>
                  {x.isHTML !== undefined ? parse(x.text) : x.text}
                </div>
              );
            } else {
              return (
                <div hidden={hidden} className={className + " displayFlex"}>
                  {x.isHTML !== undefined ? parse(x.text) : x.text}
                  {"\u00A0"}
                  <CheckBoxControl
                    id={this.props.name + "___" + x.field}
                    name={this.props.name + "___" + x.field}
                    onChange={this.handleSelectUnselect}
                    //text={x.text}
                    displayError={this.displayError}
                    className={
                      x.className !== undefined ? x.className : "checkbox"
                    }
                  />
                  {"\u00A0"}
                </div>
              );
            }
          } else {
            return (
              <div hidden={hidden} className={className}>
                {helpID != -1 &&
                  this.createHelpLink(
                    this.props.formHelpPage,
                    helpID,
                    "lg",
                    false,
                    true
                  )}
                {(x.allowSort === undefined ? true : !x.allowSort) && (
                  <React.Fragment>
                    {x.isHTML !== undefined ? parse(x.text) : x.text}
                  </React.Fragment>
                )}
                {(x.allowSort === undefined ? false : x.allowSort) &&
                  (x.currentSort === undefined ? DSC : x.currentSort) ==
                    ASC && (
                    <React.Fragment>
                      <a
                        onClick={() => {
                          x.SortByColumn(x.field, DSC);
                        }}
                      >
                        {x.isHTML !== undefined ? parse(x.text) : x.text}
                      </a>
                      <a
                        className="message-link sort-icon"
                        onClick={() => {
                          x.SortByColumn(x.field, DSC);
                        }}
                      >
                        <Icon name="arrow_drop_up" size="sm" />
                      </a>
                    </React.Fragment>
                  )}
                {(x.allowSort === undefined ? false : x.allowSort) &&
                  (x.currentSort === undefined ? DSC : x.currentSort) ==
                    DSC && (
                    <React.Fragment>
                      <a
                        onClick={() => {
                          x.SortByColumn(x.field, ASC);
                        }}
                      >
                        {x.isHTML !== undefined ? parse(x.text) : x.text}
                      </a>
                      <a
                        className="message-link sort-icon"
                        onClick={() => {
                          x.SortByColumn(x.field, ASC);
                        }}
                      >
                        <Icon name="arrow_drop_down" size="sm" />
                      </a>
                    </React.Fragment>
                  )}
              </div>
            );
          }
      }
    }
  }

  createField(x, hideMark, isRequired, ReadOnly, hidden) {
    let fieldType = this.calculateFieldType(x);
    if (
      (this.state.values.___recordStatus == 1 ||
        this.state.values.___recordStatus == 2 ||
        this.state.values.___recordStatus == 3) &&
      !ReadOnly
    ) {
      let disabled = x.disabled === undefined ? false : x.disabled;
      let disabledOnFieldWithAnd =
        x.disabledOnFieldWithAnd === undefined
          ? false
          : x.disabledOnFieldWithAnd;
      let disabledOnField =
        x.disabledOnField === undefined ? "" : x.disabledOnField;
      let disabledOnValue =
        x.disabledOnValue === undefined ? true : x.disabledOnValue;
      let value = null;
      if (disabledOnField != "") {
        if (Array.isArray(disabledOnField)) {
          if (disabledOnFieldWithAnd) {
            let i = 0;
            for (i = 0; i < disabledOnValue.length; i++) {
              let disabledRow = true;
              let j = 0;
              for (j = 0; j < disabledOnField.length; j++) {
                value = this.findValue(disabledOnField[j]);
                if (Array.isArray(disabledOnValue[i][j])) {
                  disabledRow =
                    disabledRow &&
                    this.checkAvailability(disabledOnValue[i][j], value);
                } else {
                  disabledRow = disabledRow && value == disabledOnValue[i][j];
                }
              }
              disabled = disabled || disabledRow;
            }
          } else {
            let i = 0;
            for (i = 0; i < disabledOnField.length; i++) {
              value = this.findValue(disabledOnField[i]);
              if (Array.isArray(disabledOnValue[i])) {
                disabled =
                  disabled || this.checkAvailability(disabledOnValue[i], value);
              } else {
                disabled = disabled || value == disabledOnValue[i];
              }
            }
          }
        } else {
          value = this.findValue(disabledOnField);
          if (Array.isArray(disabledOnValue)) {
            disabled =
              disabled || this.checkAvailability(disabledOnValue, value);
          } else {
            disabled = disabled || value == disabledOnValue;
          }
        }
      }
      if (
        x.hiddenOnField !== undefined &&
        x.hiddenOnFieldNoClear === undefined &&
        hidden &&
        fieldType != "label" &&
        fieldType != "link" &&
        fieldType != "checkbox" &&
        fieldType != "radio" &&
        fieldType != "hidden"
      ) {
        this.state.values[x.field] = null;
      }
      value = this.state.values[x.field];
      if (value === undefined || value == null) value = "";
      if (
        fieldType != "label" &&
        fieldType != "link" &&
        fieldType != "checkbox" &&
        fieldType != "radio" &&
        fieldType != "hidden"
      ) {
        this.numberOfRequiredFields =
          this.numberOfRequiredFields + (isRequired ? 1 : 0);
      }
      switch (fieldType) {
        case "label":
          if (
            (x.isNumber !== undefined || x.isNumber) &&
            this.props.data[x.field] !== undefined
          ) {
            let decimals =
              x.displayDecimals === undefined
                ? x.decimals === undefined
                  ? 2
                  : x.decimals
                : x.displayDecimals;
            return (
              <div>
                {x.icon !== undefined && <Icon name={x.icon} />}
                <FormatNumberControl
                  value={this.props.data[x.field]}
                  fixedDecimalScale={decimals == -1 ? false : true}
                  decimalScale={decimals == -1 ? null : decimals}
                  displayType={"text"}
                  thousandSeparator={
                    x.displayThousands === undefined ? true : x.displayThousands
                  }
                  prefix={x.prefix === undefined ? "" : x.prefix}
                  suffix={x.suffix === undefined ? "" : x.suffix}
                />
                {x.iconpost !== undefined && <Icon name={x.iconpost} />}
              </div>
            );
          } else {
            return (
              <div>
                {x.prefix !== undefined && (
                  <React.Fragment>{x.prefix}</React.Fragment>
                )}
                {x.icon !== undefined && <Icon name={x.icon} />}
                {x.isHTML !== undefined
                  ? parse(this.props.data[x.field])
                  : this.props.data[x.field]}
                {x.iconpost !== undefined && <Icon name={x.iconpost} />}
                {x.suffix !== undefined && (
                  <React.Fragment>{x.suffix}</React.Fragment>
                )}
              </div>
            );
          }
        case "number":
          let decimals = x.decimals === undefined ? 2 : x.decimals;
          if (value != "") {
            if (this.numberOfDecimals(value) > decimals && decimals != -1) {
              value = value.toFixed(decimals);
            }
          }
          return (
            <InputControl
              type="number"
              id={this.props.name + "___" + x.field}
              name={this.props.name + "___" + x.field}
              icon={x.icon}
              iconpost={x.iconpost}
              prefix={x.prefix}
              suffix={x.suffix}
              placeholder={x.placeholder}
              disabled={disabled}
              value={value}
              onChange={this.handleChange}
              minVal={isNaN(x.minVal) ? this.props.data[x.minVal] : x.minVal}
              maxVal={isNaN(x.maxVal) ? this.props.data[x.maxVal] : x.maxVal}
              increments={
                isNaN(x.increments)
                  ? this.props.data[x.increments]
                  : x.increments
              }
              decimals={x.decimals}
              valueException={x.valueException}
              required={isRequired}
              requiredMark={isRequired && !hideMark}
              maxLength={x.maxLength}
              displayError={this.displayError}
              className={x.className !== undefined ? x.className : "text-right"}
              wrapClassName={x.wrapClassName}
              iconClassName={x.iconClassName}
            />
          );
        case "password":
          return (
            <PasswordInputControl
              tag={x.tag}
              type={fieldType}
              id={this.props.name + "___" + x.field}
              name={this.props.name + "___" + x.field}
              icon={x.icon}
              iconpost={x.iconpost}
              prefix={x.prefix}
              suffix={x.suffix}
              placeholder={x.placeholder}
              disabled={disabled}
              value={value}
              onChange={this.handleChange}
              required={isRequired}
              requiredMark={isRequired && !hideMark}
              maxLength={x.maxLength}
              validationRequired={x.validationRequired}
              minNumberCharacter={8}
              minNumberSpecialCharacter={0}
              maxNumberCharacter={20}
              displayError={this.displayError}
              className={x.className}
              wrapClassName={x.wrapClassName}
              iconClassName={x.iconClassName}
              hasShowIcon={x.hasShowIcon}
            />
          );
        case "text":
        case "phone":
        case "email":
          return (
            <InputControl
              type={fieldType}
              id={this.props.name + "___" + x.field}
              name={this.props.name + "___" + x.field}
              icon={x.icon}
              iconpost={x.iconpost}
              prefix={x.prefix}
              suffix={x.suffix}
              placeholder={x.placeholder}
              disabled={disabled}
              value={value}
              onChange={this.handleChange}
              required={isRequired}
              requiredMark={isRequired && !hideMark}
              maxLength={x.maxLength}
              displayError={this.displayError}
              className={x.className}
              wrapClassName={x.wrapClassName}
              iconClassName={x.iconClassName}
            />
          );
        case "file":
          return (
            <InputControl
              type={fieldType}
              id={this.props.name + "___" + x.field}
              name={this.props.name + "___" + x.field}
              disabled={disabled}
              value={value}
              onChange={this.handleChange}
              required={isRequired}
              requiredMark={isRequired && !hideMark}
              displayError={this.displayError}
              className={x.className}
            />
          );
        case "link":
          return (
            <div>
              {x.icon !== undefined && <Icon name={x.icon} />}
              {x.onClick !== undefined && (
                <a
                  className="nav-link-2"
                  target="_blank"
                  onClick={() => x.onClick(this)}
                >
                  {value}
                </a>
              )}
              {this.props.data[x.link] != null &&
                this.props.data[x.link] != "" &&
                x.onClick === undefined && (
                  <a
                    className="nav-link-2"
                    target="_blank"
                    href={this.props.data[x.link]}
                  >
                    {value}
                  </a>
                )}
              {x.onClick === undefined &&
                (this.props.data[x.link] == null ||
                  this.props.data[x.link] == "") && (
                  <div>{this.props.data[x.field]}</div>
                )}
              {x.iconpost !== undefined && <Icon name={x.iconpost} />}
            </div>
          );
        case "checkbox":
          return (
            <CheckBoxControl
              id={this.props.name + "___" + x.field}
              name={this.props.name + "___" + x.field}
              value={value}
              integerValue={x.integerValue}
              onChange={this.handleChange}
              disabled={disabled}
              text={x.text}
              displayError={this.displayError}
              wrapClassName={x.wrapClassName}
              className={x.className !== undefined ? x.className : "checkbox"}
            />
          );
        case "button":
          return (
            <Button
              name={this.props.name + "___" + x.field}
              type="button"
              className="buttonSm"
              color="primary"
              onClick={() => x.onClick(this)}
              disabled={disabled}
            >
              <Icon name={x.icon} />
            </Button>
          );
      }
    } else {
      if (
        x.field !== undefined &&
        this.props.data[x.field] === undefined &&
        fieldType != "button" &&
        fieldType != "file"
      ) {
        if (Object.keys(this.props.data).length > 0) {
          console.log(
            "ERROR ON FORM, Invalid field",
            x.field,
            this.props.data[x.field],
            this.props.data
          );
        }
        return (
          <div>
            <font color="red">undefined field!</font>
          </div>
        );
      } else if (
        fieldType != "checkbox" &&
        fieldType != "button" &&
        fieldType != "file" &&
        this.props.data[x.field] == null
      ) {
        return <div />;
      } else {
        switch (fieldType) {
          case "number":
            let decimals =
              x.displayDecimals === undefined
                ? x.decimals === undefined
                  ? 2
                  : x.decimals
                : x.displayDecimals;
            if (decimals == -1) {
              return (
                <div>
                  {x.icon !== undefined && <Icon name={x.icon} />}
                  <FormatNumberControl
                    value={this.props.data[x.field]}
                    fixedDecimalScale={false}
                    displayType={"text"}
                    thousandSeparator={
                      x.displayThousands === undefined
                        ? true
                        : x.displayThousands
                    }
                    prefix={x.prefix === undefined ? "" : x.prefix}
                    suffix={x.suffix === undefined ? "" : x.suffix}
                  />
                  {x.iconpost !== undefined && <Icon name={x.iconpost} />}
                </div>
              );
            } else {
              return (
                <div>
                  {x.icon !== undefined && <Icon name={x.icon} />}
                  <FormatNumberControl
                    value={this.props.data[x.field]}
                    fixedDecimalScale={true}
                    decimalScale={decimals}
                    displayType={"text"}
                    thousandSeparator={
                      x.displayThousands === undefined
                        ? true
                        : x.displayThousands
                    }
                    prefix={x.prefix === undefined ? "" : x.prefix}
                    suffix={x.suffix === undefined ? "" : x.suffix}
                  />
                  {x.iconpost !== undefined && <Icon name={x.iconpost} />}
                </div>
              );
            }
          case "password":
            return <div>**********</div>;
          case "textarea":
          case "text":
          case "phone":
          case "email":
          case "label":
            if (
              (x.isNumber !== undefined ||
                (x.isNumber && this.props.data[x.field])) !== undefined
            ) {
              let decimals =
                x.displayDecimals === undefined
                  ? x.decimals === undefined
                    ? 2
                    : x.decimals
                  : x.displayDecimals;
              return (
                <div>
                  {x.icon !== undefined && <Icon name={x.icon} />}
                  <FormatNumberControl
                    value={this.props.data[x.field]}
                    fixedDecimalScale={decimals == -1 ? false : true}
                    decimalScale={decimals == -1 ? null : decimals}
                    displayType={"text"}
                    thousandSeparator={
                      x.displayThousands === undefined
                        ? true
                        : x.displayThousands
                    }
                    prefix={x.prefix === undefined ? "" : x.prefix}
                    suffix={x.suffix === undefined ? "" : x.suffix}
                  />
                  {x.iconpost !== undefined && <Icon name={x.iconpost} />}
                </div>
              );
            } else {
              return (
                <div>
                  {x.prefix !== undefined && (
                    <React.Fragment>{x.prefix}</React.Fragment>
                  )}
                  {x.icon !== undefined && <Icon name={x.icon} />}
                  {x.isHTML !== undefined
                    ? parse(this.props.data[x.field])
                    : this.props.data[x.field]}
                  {x.iconpost !== undefined && <Icon name={x.iconpost} />}
                  {x.suffix !== undefined && (
                    <React.Fragment>{x.suffix}</React.Fragment>
                  )}
                </div>
              );
            }

          case "link":
            return (
              <div>
                {x.icon !== undefined && <Icon name={x.icon} />}
                {x.onClick !== undefined && (
                  <a
                    className="nav-link-2"
                    target="_blank"
                    onClick={() => x.onClick(this)}
                  >
                    {this.props.data[x.field]}
                  </a>
                )}
                {this.props.data[x.link] != null &&
                  this.props.data[x.link] != "" &&
                  x.onClick === undefined && (
                    <a
                      className="nav-link-2"
                      target="_blank"
                      href={this.props.data[x.link]}
                    >
                      {this.props.data[x.field]}
                    </a>
                  )}
                {x.onClick === undefined &&
                  (this.props.data[x.link] == null ||
                    this.props.data[x.link] == "") && (
                    <div>{this.props.data[x.field]}</div>
                  )}
                {x.iconpost !== undefined && <Icon name={x.iconpost} />}
              </div>
            );
          case "checkbox":
            return (
              <div className={x.className}>
                {(x.textRight === undefined || x.textRight == false) &&
                x.text !== undefined
                  ? x.text + "  "
                  : ""}
                {/* {" "}{x.title} */}
                {this.props.data[x.field] ? (
                  <Icon name={faCheckSquare} />
                ) : (
                  <Icon name={faSquare} />
                )}
                {x.textRight !== undefined &&
                x.textRight == true &&
                x.text !== undefined
                  ? "  " + x.text
                  : ""}
              </div>
            );
          case "date":
            return (
              <div>
                {this.props.data[x.field] !== undefined &&
                this.props.data[x.field] != null
                  ? Moment(this.props.data[x.field].substring(0, 10)).format(
                      x.dateFormat === undefined ? "MM/DD/YYYY" : x.dateFormat
                    )
                  : ""}
              </div>
            );
          case "file":
            return (
              <InputControl
                type={fieldType}
                id={this.props.name + "___" + x.field}
                name={this.props.name + "___" + x.field}
                disabled={true}
                required={isRequired}
                requiredMark={isRequired && !hideMark}
                displayError={this.displayError}
                className={x.className}
              />
            );
          case "button":
            return (
              <Button
                name={this.props.name + "___" + x.field}
                type="button"
                className="buttonSm"
                color="primary"
                onClick={() => x.onClick(this)}
                disabled={false}
              >
                <Icon name={x.icon} />
              </Button>
            );
        }
      }
    }
  }

  render() {
    this.numberOfRequiredFields = 0;
    if (
      this.props.isGridSelect !== undefined ? this.props.isGridSelect : false
    ) {
      return (
        <React.Fragment>
          <div
            //key={this.props.name}
            hidden={
              (this.props.hiddenSelect !== undefined &&
                this.props.hiddenSelect == true) ||
              this.state.values.___recordStatus >= 4 ||
              (this.props.data == {} && this.state.values.___recordStatus <= 2)
            }
            className={
              this.props.className === undefined ? "" : this.props.className
            }
          >
            <ModalControl //key={this.modalkey}
              ref={this.modal}
            />
            {this.props.display.map((x) => {
              return this.processTag(x);
            })}
          </div>
          {this.addDivider(
            this.props.showDivider !== undefined
              ? !this.props.showDivider
              : true,
            true
          )}
        </React.Fragment>
      );
    } else if (
      this.props.tableRow !== undefined ? this.props.tableRow : false
    ) {
      return (
        <tr
          //key={this.props.name}
          hidden={
            (this.props.hiddenSelect !== undefined &&
              this.props.hiddenSelect == true) ||
            this.state.values.___recordStatus >= 4 ||
            (this.props.data == {} && this.state.values.___recordStatus <= 2)
          }
          className={
            this.props.className === undefined ? "" : this.props.className
          }
        >
          <ModalControl //key={this.modalkey}
            ref={this.modal}
          />
          {this.props.display.map((x) => {
            return this.processTag(x);
          })}
        </tr>
      );
    } else {
      return (
        <React.Fragment>
          <div
            className="w-100"
            hidden={
              (this.props.hiddenSelect !== undefined &&
                this.props.hiddenSelect == true) ||
              this.state.values.___recordStatus >= 4 ||
              (this.props.data == {} && this.state.values.___recordStatus <= 2)
            }
          >
            <ModalControl //key={this.modalkey}
              ref={this.modal}
            />
            {(
              this.props.tableDisplay !== undefined
                ? this.props.tableDisplay
                : false
            ) ? (
              <tbody
                className={
                  this.props.className === undefined ? "" : this.props.className
                }
              >
                {this.props.display.map((x) => {
                  return this.processTag(x);
                })}
              </tbody>
            ) : (
              <React.Fragment>
                <div
                  className={
                    this.props.className === undefined
                      ? "table"
                      : this.props.className
                  }
                >
                  {this.props.display && this.props.display.map((x) => {
                    return this.processTag(x);
                  })}
                </div>
                {this.numberOfRequiredFields > 0 &&
                  (this.props.hideRequiredMark === undefined ||
                    !this.props.hideRequiredMark) && (
                    <div className="title-center">
                      <font color="red">* required field</font>
                    </div>
                  )}
              </React.Fragment>
            )}
          </div>
          {this.addDivider(
            this.props.showDivider !== undefined
              ? !this.props.showDivider
              : true,
            true
          )}
        </React.Fragment>
      );
    }
  }
}

export default FormControl;
