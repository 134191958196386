const ApiUrl = {
  API_URL_HOST: "http://localhost:3000/rest/",
  REACT_URL_HOST: "http://localhost:8081/",
  REACT_URL_LOGIN: "http://localhost:8088/",
};
const PageUrl = window.location.href;
let PageName = "";
let app_env = "dev";
let sal_urls = {
  "dev": "http://nqdc-loginhandler-api.webfarm-dev.ms.com/nqdc_login_handler/rest/",
  "qa": "http://nqdc-loginhandler-api.webfarm-qa.ms.com/nqdc_login_handler/rest/",
  "prod": "http://nqdc-loginhandler-api.webfarm.ms.com/nqdc_login_handler/rest/"
};
let sal_urls_internals = {
  "dev": "http://nqdc-loginhandler-api-internal.webfarm-dev.ms.com/nqdc_login_handler/rest/",
  "qa": "http://nqdc-loginhandler-api-internal.webfarm-qa.ms.com/nqdc_login_handler/rest/",
  "prod": "http://nqdc-loginhandler-api-internal.webfarm.ms.com/nqdc_login_handler/rest/"
};
let react_internal_urls={
  "dev":"http://nqdc-deferral-internal.webfarm-dev.ms.com/nqdc_deferral_ui/",
  "qa":"http://nqdc-deferral-internal.webfarm-qa.ms.com/nqdc_deferral_ui/",
  "prod":"http://nqdc-deferral-internal.webfarm.ms.com/nqdc_deferral_ui/"
};
let react_urls={
  "dev":"https://dev-nqdc.morganstanleyatwork.com",
  "qa":"https://qa-nqdc.morganstanleyatwork.com",
  "qa1":"https://qa1-nqdc.morganstanleyatwork.com",
  "preprod":"https://preprod-deferredcomp.morganstanleyatwork.com",
  "prod":"https://deferredcomp.morganstanleyatwork.com"
};
export const  processEnvironment=()=>{
if (process.env.NODE_ENV === "production") {
  const intPos = PageUrl.indexOf("//");
  if (intPos != -1) {
    const intPos2 = PageUrl.substr(intPos + 2).search(/[\.:\/]/);
    if (intPos2 != -1) {
      PageName = PageUrl.substr(intPos + 2, intPos2).toLowerCase();
      if (
        PageName == "localhost" &&
        PageUrl.substr(intPos + 2 + intPos2, 1) == ":"
      ) {
        //ApiUrl.API_URL_HOST = "https://localhost:7173/";
        ApiUrl.API_URL_HOST="";
        ApiUrl.REACT_URL_HOST = "http://localhost:8081/";
      } else if (PageName == "localhost") {
        //ApiUrl.API_URL_HOST = "https://localhost:7173/";
        ApiUrl.REACT_URL_HOST = "http://localhost:8081/";
      } else if (PageName == "www" || PageName == "login") {
        ApiUrl.API_URL_HOST = "https://loginapi.deferral.com/";
        ApiUrl.REACT_URL_HOST = "https://participant.deferral.com/react/";
      }     
      else {
        
      app_env = getAppEnvironment(window.location.hostname);
      let isInternal=window.location.hostname.match(/(internal)/);
        //ApiUrl.API_URL_HOST = "https://" + PageName + "api.deferral.com/";
        if(isInternal){
          ApiUrl.API_URL_HOST=sal_urls_internals[app_env];
          ApiUrl.REACT_URL_HOST=react_internal_urls[app_env];
        }else{
          //ApiUrl.API_URL_HOST=sal_urls[app_env];
          ApiUrl.API_URL_HOST = window.location.origin + "/rest/";
          ApiUrl.REACT_URL_HOST=react_urls[app_env];
        }
        // ApiUrl.REACT_URL_HOST =
        //   "https://" + PageName.replace("login", "") + ".deferral.com/react/"; 
      }
    }
  }
}
};
processEnvironment();
export const config = ApiUrl;
export const algorithm = "aes-256-cbc";
export const cypherkey = "58945803628475210256298743257410";
export const cypheriv = "2578018473365974";

// THESE ARE PROVISIONAL CONSTANTS IN HERE!
export const NewRecord = -28888;
export const NullRecord = 0; //MUST BE 0 SO THE PARTICIPANT SIDE WORKS

export function getAppEnvironment(hostname) {  
  let match = hostname.match(/((dev|qa|qa1))?-nqdc.morganstanleyatwork.com/);
  let matchForProd = hostname.match(/((preprod))?-deferredcomp.morganstanleyatwork.com/);
  let app_env = "prod";
  const matchedVal = (match && match[2]) || (matchForProd && matchForProd[2]);
  if(matchedVal){
    switch (matchedVal) {
      case 'preprod': app_env = "preprod"; break;
      case 'qa1': app_env = "qa1"; break;
      case 'qa': app_env = "qa"; break;
      case 'dev': app_env = "dev"; break;
      default: app_env = "prod"; break;
    }
  }
  return app_env;
}